<script setup lang="ts">
const {
  title,
  color
} = defineProps({
  title: {
    type: String,
    required: true
  },
  color: {
    type: String,
    required: false,
    default: ''
  }
})
</script>

<template>
  <div
      class="category-item"
      :class="color"
  >
    {{ title }}
  </div>
</template>

<style scoped lang="scss">
.category-item {
  padding: .3rem .5rem;
  border-radius: 4px;
  color: #1a20ff;
  font-size: 14px;
  background: #edeeff;

  &.green {
    color: #009935;
    background: #ebfaf1;
  }
}
</style>
