<script setup lang="ts">
import BlockInfo from "@/components/block-info/BlockInfo.vue";

import {IBlockPropValue} from "@/interfaces/Blocks";

const places:IBlockPropValue[] = [
  {
    key: '1',
    prop: 'Регион',
    value: 'Дальневосточный федеральный округ'
  },
  {
    key: '2',
    prop: 'Город',
    value: 'Петропавловск - Камчатский'
  },
];
const professionals:IBlockPropValue[] = [
  {
    key: '1',
    prop: 'Специальность',
    value: 'Гематология (ВО)'
  },
  {
    key: '2',
    prop: 'Аккредитация',
    value: 'до 12.12.2023'
  },
  {
    key: '3',
    prop: 'Место работы',
    value: 'Медитекс Медицинский центр'
  },
  {
    key: '4',
    prop: 'Тип приёма',
    value: 'Стационарный'
  },
  {
    key: '5',
    prop: 'Специальность',
    value: 'Бактериология (ВО)'
  },
  {
    key: '6',
    prop: 'Аккредитация',
    value: 'до 12.12.2023'
  },
  {
    key: '7',
    prop: 'Место работы',
    value: 'Клиника Бызлевского'
  },
  {
    key: '8',
    prop: 'Тип приёма',
    value: 'Амбулаторный'
  },
  {
    key: '9',
    prop: 'Специальность',
    value: 'Вирусология (ВО)'
  },
  {
    key: '10',
    prop: 'Аккредитация',
    value: 'до 12.08.2023'
  },
];
</script>

<template>
  <div class="block-general">
    <div class="block-general__content flex">
      <BlockInfo
          title="Местоположение"
          :values="places"
      />
      <BlockInfo
          title="Профессиональная информация"
          :values="professionals"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.block-general {
  &__content {
    flex-direction: column;
    gap: 48px;
  }
}
</style>
