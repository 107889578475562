<script setup lang="ts">
import {watch} from 'vue';
import { storeToRefs } from 'pinia';
import {useFilterStore} from "@/store/filters";
import { useRoute } from "vue-router"

import AppHeaderDefault from '@/components/header/AppHeaderDefault.vue';
import NavigationLeftAside from "@/components/navigation/NavigationLeftAside.vue";
import FilterAside from "@/components/filters/filter-aside/FilterAside.vue";

const filterStore = useFilterStore();
const { isShowAside } = storeToRefs(filterStore);

const route = useRoute();
watch(route, () => {
  filterStore.clearAll();
})
</script>

<template>
  <div class="app"
       :class="{'app--fixed': isShowAside}"
  >
    <div class="app__container grid">
      <NavigationLeftAside />

      <div>
        <AppHeaderDefault />

        <main class="app__main">
          <div class="container container-fluid">
            <router-view/>
          </div>
        </main>
      </div>
    </div>

    <FilterAside v-if="isShowAside"  />
  </div>
</template>

<style lang="scss">
@import "assets/styles/app.scss";

.app {
  &__container {
    grid-template-columns: 320px calc(100% - 320px);
  }

  &__main {
    padding: 40px 60px 100px 48px;
  }
}
</style>
