<script setup lang="ts">
import {watch, ref} from 'vue';
import { storeToRefs } from 'pinia';
import {useTableStore} from "@/store/table";

const tableStore = useTableStore();
const {isSelectAllItems} = storeToRefs(tableStore);

const isChecked = ref<boolean>(false);

watch((isSelectAllItems), () => {
  isChecked.value = isSelectAllItems.value;
});
</script>

<template>
  <div class="cell-table-id">
    <a-checkbox v-model:checked="isChecked">123123</a-checkbox>
  </div>
</template>

<style lang="scss">
.cell-table-id label {
  color: #909099;
  font-size: 16px;
  font-weight: 500 !important;
  font-family: 'Gilroy-Medium', serif !important;
}
</style>
