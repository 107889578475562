<script setup lang="ts">
import {ICompanyGoal} from "@/interfaces/Company";

const {options} = defineProps({
  options: {
    type: Object as () => ICompanyGoal,
    required: true
  }
})
</script>

<template>
  <div class="company-goal-info">
    <h6 class="company-goal-info__title">
      {{ options.title }}
    </h6>

    <div class="company-goal-info__container grid cols-2 gap-2 justify-between">
      <h5 class="company-goal-info__value">
        {{ options.value }}
      </h5>

      <div class="company-goal-info__icons flex gap-2 items-center flex-wrap">
        <img
          v-for="icon in options.icons"
          :key="icon"
          :src="icon"
          alt=""
          loading="lazy"
          class="company-goal-info__icons__item"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.company-goal-info {
  &__title {
    margin-bottom: .3rem;
    color: #A8A8B2;
    font-size: 16px;
    font-weight: 600;
  }

  &__value {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}
</style>
