<script setup lang="ts">
import TemplateCardMessage from "@/components/companies-templates/TemplateCardMessage.vue";
import TemplateCardEvent from "@/components/companies-templates/TemplateCardEvent.vue";
import ListTags from '@/components/tags/ListTags.vue';

import {ITemplate} from "@/interfaces/Templates";

const {
  type,
  template
} = defineProps({
  template: {
    type: Object as () => ITemplate,
    required: true
  }
});
</script>

<template>
  <div class="template-card"
       :class="'template-card--' + template.type"
  >
    <div class="template-card__preview">
      <div class="template-card__preview__content">
        <TemplateCardMessage v-if="template.type === 'message'" />
        <TemplateCardEvent v-if="template.type === 'event'" />
      </div>
    </div>
    <div class="template-card__tags mt-2 mb-2">
      <ListTags
          :tags="template.tags"
      />
    </div>
    <h6 class="template-card__title mb-2">
      {{ template.title }}
    </h6>
    <div class="template-card__date flex gap-1">
      <img
          src="/images/icon-date.svg"
          alt=""
          class="template-card__date__img"
      />
      <span class="template-card__date__text">
        {{ template.date }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.template-card {
  width: 360px;
  margin-bottom: 20px;

  &__preview {
    height: 244px;
    overflow: hidden;
    border-radius: 12px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    background: #F7F8FF;

    &__content {
      width: 180px;
      margin: 0 auto;
      background: #F7F8FF;
    }
  }

  &__title {
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.32px;
  }

  &__date {
    color: #C0C0CC;
    font-size: 14px;
    font-weight: 600;
  }
}

.template-card--message {
  & .template-card__preview__content {
    width: 332px;
  }
}
</style>
