<script setup lang="ts">
import OCMTableDefault from "@/components/tables/OCMTableDefault.vue";
import TableAdaptiveHeight from "@/components/tables/TableAdaptiveHeight.vue";
import PaginationDefault from "@/components/pagination/PaginationDefault.vue";
import CellTableId from "@/components/clients/CellTableId.vue";
import CellTableFIO from "@/components/clients/CellTableFIO.vue";
import CellDetails from "@/components/tables/CellDetails.vue";
import CellStatus from '@/components/tables/CellStatus.vue';

import {IThead} from "@/interfaces/Table";

const listTHead:IThead[] = [
  {
    key: 'id',
    label: 'ID',
    content: CellTableId
  },
  {
    key: 'fio',
    label: 'ФИО',
    content: CellTableFIO
  },
  {
    key: 'specialization',
    label: 'Специальность'
  },
  {
    key: 'status',
    label: 'Текущий статус по рассылке',
    content: CellStatus
  },
  {
    key: 'details',
    label: '',
    content: CellDetails
  }
];
const clients = [
  {
    id: 1,
    fio: 'Ростов Олег Викторович',
    specialization: 'Акушерсткое дело',
    status: 1,
  },
  {
    id: 2,
    fio: 'Иванова Наталья Александровна',
    specialization: 'Врач',
    status: 1,
  }
];
</script>

<template>
  <div class="company-clients">
    <TableAdaptiveHeight>
      <OCMTableDefault
          :listTHead="listTHead"
          :data="clients"
      />
    </TableAdaptiveHeight>

    <PaginationDefault
        :currentPage="1"
        :countPages="3"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
