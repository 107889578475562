<script setup lang="ts">
  const {data} = defineProps({
    data: {
      type: Object,
      required: false,
      default: {}
    }
  });
</script>

<template>
  <a class="cell-name"
  >
    {{ data.name }}
  </a>
</template>
