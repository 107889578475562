<script setup lang="ts">

</script>

<template>
  <div class="cell-table-control flex gap-2 items-center justify-end">
    <button class="ocm-btn--icon">
      <img src="/images/icon-table-edit.svg"
           alt=""
      />
    </button>
    <button class="ocm-btn--icon">
      <img src="/images/icon-cell-archive.svg"
           alt=""
      />
    </button>
  </div>
</template>
