<script lang="ts" setup>
import {ref, onMounted} from 'vue';

import BlockFilters from "@/components/filters/BlockFilters.vue";
import TableAdaptiveHeight from "@/components/tables/TableAdaptiveHeight.vue";
import PaginationDefault from "@/components/pagination/PaginationDefault.vue";
import NavigationTabs from "@/components/navigation/NavigationTabs.vue";
import OCMTableDefault from "@/components/tables/OCMTableDefault.vue";
import CellTableId from "@/components/clients/CellTableId.vue";
import CellTableControl from "@/components/tables/CellTableControl.vue";
import CellTableFIO from "@/components/clients/CellTableFIO.vue";
import ClientsFilterControl from "@/components/clients/ClientsFilterControl.vue";
import CRMLoader from "@/components/loader/CRMLoader.vue";
import ClientsUpdate from "@/components/clients-update/ClientsUpdate.vue";
import CellTableControlArchive from '@/components/clients-archive/CellTableControl.vue';

import {type INavTab} from "@/interfaces/Navigation";
import {type IFilter} from '@/interfaces/Filters';
import {type IThead} from '@/interfaces/Table';

const tabs:INavTab[] = [
  {
    key: 'clients',
    label: 'Список клиентов'
  },
  {
    key: 'archive',
    label: 'Архив'
  },
  {
    key: 'updates',
    label: 'Обновления'
  }
];
const filters:IFilter[] = [
  {
    key: 'base',
    label: 'База партнёра',
    type: 'select'
  },
  {
    key: 'segment',
    label: 'Сегмент',
    type: 'selectLine'
  },
  {
    key: 'status',
    label: 'Статус клиента',
    type: 'selectLine'
  },
  {
    key: 'all',
    label: 'Все фильтры',
    type: 'button'
  }
];
const listTHeadClients:IThead[] = [
  {
    key: 'id',
    label: 'ID',
    content: CellTableId,
    thClass: 'thin'
  },
  {
    key: 'fio',
    label: 'ФИО',
    content: CellTableFIO
  },
  {
    key: 'specialization',
    label: 'Специальность'
  },
  {
    key: 'phone',
    label: 'Телефон'
  },
  {
    key: 'mail',
    label: 'Почта'
  },
  {
    key: 'city',
    label: 'Город'
  },
  {
    key: 'control',
    label: '',
    content: CellTableControl,
    thClass: 'th-control'
  }
];
const listTHeadArchive:IThead[] = [
  {
    key: 'id',
    label: 'ID',
    content: CellTableId
  },
  {
    key: 'fio',
    label: 'ФИО',
    content: CellTableFIO
  },
  {
    key: 'specialization',
    label: 'Специальность'
  },
  {
    key: 'phone',
    label: 'Телефон'
  },
  {
    key: 'mail',
    label: 'Почта'
  },
  {
    key: 'city',
    label: 'Город'
  },
  {
    key: 'control',
    label: '',
    content: CellTableControlArchive,
    thClass: 'th-control'
  }
]
const clients = [
  {
    id: 1,
    fio: 'Ростов Олег Викторович',
    specialization: 'Акушерсткое дело',
    phone: '796780120372',
    mail: 'oleg@yandex.ru',
    city: 'Петропавловск-Камчатский'
  },
  {
    id: 2,
    fio: 'Иванова Наталья Александровна',
    specialization: 'Врач',
    phone: '+796780820372',
    mail: 'natasha@yandex.ru',
    city: 'Смоленск'
  },
  {
    id: 4,
    fio: 'Сидоренкова Кристина',
    specialization: 'Врач',
    phone: '+796780820372',
    mail: 'natasha@yandex.ru',
    city: 'Смоленск'
  },
  {
    id: 5,
    fio: 'Антонова Лера Олеговна',
    specialization: 'Врач',
    phone: '+796780820372',
    mail: 'natasha@yandex.ru',
    city: 'Москва'
  },
  {
    id: 6,
    fio: 'Сидорова Ирина Александровна',
    specialization: 'Врач',
    phone: '+796780820372',
    mail: 'natasha@yandex.ru',
    city: 'Смоленск'
  }
];
const clientsFiltered = ref<Array<any>>([]);

const activeView = ref<string>('archive');
const isLoading = ref<boolean>(false);
const activeTH = ref<IThead[]>(listTHeadClients);

const handleChangeTab = (tab:INavTab) => {
  isLoading.value = true;
  activeView.value = tab.key;

  switch (tab.key) {
    case 'archive':
      activeTH.value = listTHeadArchive;
      break;

    case 'clients':
      activeTH.value = listTHeadClients;
      break;
  }

  setTimeout(() => {
    isLoading.value = false;
  }, 800);
}

const handleChangeCurrentPage = () => {
  console.log('handleChange');
}

const handleSearch = (searchText:string) => {
  clientsFiltered.value = clients.filter((client) => {
    if(!searchText || !searchText.trim()) {
      return client;
    }

    if(client.fio.toLowerCase().includes(searchText.toLowerCase())) {
      return client;
    }
  })
}

onMounted(() => {
  clientsFiltered.value = clients.slice();
})
</script>

<template>
  <div class="list-clients">
    <NavigationTabs
        :tabs="tabs"
        :onChangeTab="handleChangeTab"
    />
    <BlockFilters
        :listFilters="filters"
        :isShowControl="true"
    >
      <template #control>
        <ClientsFilterControl />
      </template>
    </BlockFilters>

    <CRMLoader v-if="isLoading"
               class="list-clients__loader"
    />
    <div v-if="!isLoading"
         class="list-clients__content"
    >
      <div v-if="['archive', 'clients'].includes(activeView)">
        <TableAdaptiveHeight>
          <OCMTableDefault
              :listTHead="activeTH"
              :data="clientsFiltered"
              :search="handleSearch"
          />
        </TableAdaptiveHeight>

        <div class="list-clients__pagination">
          <PaginationDefault
              :currentPage="1"
              :onChangePage="handleChangeCurrentPage"
              :countPages="3"
          />
        </div>
      </div>

      <div v-if="activeView === 'updates'">
        <ClientsUpdate />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-clients {
  &__loader {
    margin-top: 5rem;
  }

  &__content {
    margin-top: 40px;
  }
}
</style>
