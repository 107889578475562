<script setup lang="ts">
import BlockInfoValue from "@/components/clients-update/BlockInfoValue.vue";

import {IClientsContact} from "@/interfaces/Clients";

const {
  data
} = defineProps({
  data: {
    type: Object as PropType<IClientsContact>,
    required: true
  }
});
</script>

<template>
  <div class="block-info">
    <h6 class="block-info__title">
      {{ data.title }}
    </h6>

    <div class="block-info__content flex gap-4">
      <BlockInfoValue
          :isMatch="true"
          value="200"
      />
      <BlockInfoValue
          :isMatch="false"
          value="732"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.block-info {
  padding: 1.5rem 1rem;
  border: 1px solid #e8e9ff;
  border-radius: 8px;

  &__title {
    margin-bottom: 1rem;
    color: #000;
    font-weight: 700;
  }
}
</style>
