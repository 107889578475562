<script setup lang="ts">
import OCMTableDefault from "@/components/tables/OCMTableDefault.vue";
import CompanyLinksEmpty from "@/components/company/CompanyLinksEmpty.vue";

import {IThead, ITableSettings} from "@/interfaces/Table";

const items = [];
const listTHead: IThead[] = [
  {
    key: 'type',
    label: 'Тип рассылки'
  },
  {
    key: 'company_name',
    label: 'Название кампании'
  },
  {
    key: 'partner',
    label: 'Партнёр'
  },
  {
    key: 'date',
    label: 'Дата отправки'
  },
  {
    key: 'status',
    label: 'Статус'
  }
];
const tableSettings:ITableSettings = {
  empty: CompanyLinksEmpty
};
</script>

<template>
  <div class="company-links">
    <OCMTableDefault
        :listTHead="listTHead"
        :data="items"
        :settings="tableSettings"
    />
  </div>
</template>
