<script setup lang="ts">
import {PropType, ref} from 'vue';

import type {ITableAction} from '@/interfaces/Tabs';

const {
  tabs,
  change
} = defineProps({
  tabs: {
    type: Array,
    required: false,
    default: []
  },
  change: {
    type: Function,
    required: true
  }
});

const activeTab = ref<string>(tabs[0].key);

const handleTab = (key:string) => {
  change(key);
  activeTab.value = key;
}
</script>

<template>
  <nav class="tabs-buttons flex gap-1 flex-wrap">
    <a
        class="tabs-buttons__item"
        v-for="tab in tabs"
        :key="tab.key"
        :class="{'active': activeTab === tab.key}"
        @click="handleTab(tab.key)"
    >{{ tab.name }}</a>
  </nav>
</template>

<style scoped lang="scss">
.tabs-buttons {
  &__item {
    border-radius: 10px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.48px;
    cursor: pointer;
    background: #fff;

    &.active {
      color: #A140FF;
      background: #F9F2FF;
    }
  }
}
</style>
