<script setup lang="ts">
import {ref, watch} from 'vue';
import {useRoute} from "vue-router";
import {navigationSections} from "@/enums/navigation";

import AppLogo from "@/components/logo/AppLogo.vue";

const router = useRoute();
const activeItem = ref<string>('');
const activeRouter = ref<string>('');

const handleMouseOver = (item:any) => {
  activeItem.value = item.path;
}

const handleMouseLeave = (item:any) => {
  activeItem.value = '';
}

watch(router, (value) => {
  switch (value.name) {
    case 'clients':
      activeRouter.value = '/clients';
      break;

    default:
      activeRouter.value = value.path;
      break;
  }
})
</script>

<template>
  <div class="navigation-left-aside">
    <div class="navigation-left-aside__logo">
      <AppLogo />
    </div>

    <nav class="navigation-left-aside__list flex gap-4">
      <router-link
          v-for="navItem in navigationSections"
          :key="navItem.path"
          :to="navItem.path"
          class="navigation-left-aside__list__item"
          active-class="active-router-link"
          @mouseover="handleMouseOver(navItem)"
          @mouseleave="handleMouseLeave(navItem)"
      >
        <div class="navigation-left-aside__item__wrap_icon">
          <img :src="navItem.icon"
               alt=""
               class="navigation-left-aside__item__icon"
               v-if="navItem.path !== activeItem && activeRouter != navItem.path"
          />
          <img :src="navItem.iconEffect"
               alt=""
               class="navigation-left-aside__item__icon effect"
               v-if="navItem.path === activeItem || activeRouter === navItem.path"
          />
        </div>
        <span class="navigation-left-aside__name">{{ navItem.name }}</span>
      </router-link>
    </nav>
  </div>
</template>

<style scoped lang="scss">
.navigation-left-aside {
  height: 100%;
  min-height: 100vh;
  padding: 24px 60px;
  border-right: 1px solid #D8D8E5;
  background: #F2F3FF;

  &__logo {
    margin-bottom: 44px;
    width: 198px;
    height: 39px;
  }

  &__list {
    flex-direction: column;

    &__item {
      height: 40px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      letter-spacing: 0.32px;

      &:hover, &:active {
        background: #E4E4F2;
      }
    }

    & .navigation-left-aside__item__wrap_icon {
      height: 40px;
      width: 40px;
      color: #353538;
      font-size: 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .navigation-left-aside__name {
      color: #353538;
      font-weight: 500;
    }

    & .navigation-left-aside__list__item {
      &:hover, &:active {
        & .navigation-left-aside__item__wrap_icon {
          background: #474EFF;
        }

        & .navigation-left-aside__name {
          color: #1D1D1F !important;
          font-weight: 600;
        }
      }
    }
  }

  & .active-router-link {
    & .navigation-left-aside__item__wrap_icon {
      background: #474EFF;
    }

    & .navigation-left-aside__name {
      color: #1D1D1F !important;
      font-weight: 600;
    }
  }
}
</style>
