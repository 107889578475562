<script setup lang="ts">
import {ISelectOptionSimple} from "@/interfaces/Form";

const regions:ISelectOptionSimple[] = [
  {
    value: 'Белгородская область',
    label: 'Белгородская область'
  },
  {
    value: 'Брянская область',
    label: 'Брянская область'
  },
  {
    value: 'Владимирская область',
    label: 'Владимирская область'
  },
  {
    value: 'Воронежская область',
    label: 'Воронежская область'
  },
  {
    value: 'Ивановская область',
    label: 'Ивановская область'
  },
  {
    value: 'Калужская область',
    label: 'Калужская область'
  }
];
const cities:ISelectOptionSimple[] = [
  {
    label: 'Москва',
    value: 'Москва'
  },
  {
    label: 'Санкт-Петербург',
    value: 'Санкт-Петербург'
  },
  {
    label: 'Калининград',
    value: 'Калининград'
  },
  {
    label: 'Тюмень',
    value: 'Тюмень'
  },
  {
    label: 'Сочи',
    value: 'Сочи'
  }
];
</script>

<template>
  <div class="filter-aside-block-places flex gap-6">
    <div class="form-group">
      <a-select class="ocm-select filter-aside-select"
                placeholder="Регион"
                :options="regions"
      ></a-select>
    </div>
    <div class="form-group">
      <a-select class="ocm-select filter-aside-select"
                placeholder="Город"
                :options="cities"
      ></a-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filter-aside-block-places {
  flex-direction: column;
}
</style>
