<script setup lang="ts">
const {
    isMatch,
    value
} = defineProps({
  isMatch: {
    type: Boolean,
    required: true,
  },
  value: {
    type: [Number,String],
    required: false,
    default: ''
  }
})
</script>

<template>
  <div class="block-info-value">
    <h6 class="block-info-value__prop font-bold">
      {{ isMatch ? 'Совпадений' : 'Доступных к обновлению' }}
    </h6>
    <h6 class="block-info-value__value font-bold">{{ value }}</h6>
  </div>
</template>

<style scoped lang="scss">
.block-info-value {
  padding: 1rem;
  border-radius: 8px;
  background: #f7f8ff;

  &__prop {
    margin-bottom: .5rem;
    color: #a8a8b2;
    font-size: 16px;
  }

  &__value {
    color: #484eff;
    font-size: 20px;
  }
}
</style>
