import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import Antd from 'ant-design-vue';

const pinia = createPinia()

import 'ant-design-vue/dist/reset.css';
import './assets/styles/tailwind-styles.css';

createApp(App)
    .use(router)
    .use(Antd)
    .use(pinia)
    .mount('#app')
