<script setup lang="ts">
import CompanyGoalInfo from "@/components/company/CompanyGoalInfo.vue";

import type {ICompanyGoal} from "@/interfaces/Company";

const {
  options
} = defineProps({
  options: {
    type: Object as () => ICompanyGoal,
    required: true
  }
})
</script>

<template>
  <div class="company-goals-card flex justify-between">
    <h6 class="company-goals-card__title">
      {{ options.title }}
    </h6>

    <div class="company-goals-card__grid grid-cols-2 grid">
      <CompanyGoalInfo
          :options="options.leftOptions"
      />
      <CompanyGoalInfo
          v-if="options.rightOptions !== null"
          :options="options.rightOptions"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.company-goals-card {
  width: 489px;
  height: 232px;
  padding: 24px;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--Blue-08, #F2F3FF);
  box-shadow: 0 4px 20px 0 rgba(66, 58, 166, 0.08);
  overflow: hidden;
  background: #FFF;


  &__title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }

  &__grid {
    padding-top: 1rem;
    border-top: 1px solid #F2F3FF;
  }
}
</style>
