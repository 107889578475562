<template>
  <div class="company-links-empty">
    <img
      class="company-links-empty__img"
      src="/images/empty-table.svg"
      alt=""
    />
    <h6 class="company-links-empty__title">
      Здесь будет отображаться связанные рассылки
    </h6>
  </div>
</template>

<style scoped lang="scss">
.company-links-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__img {
    margin-bottom: 8px;
    width: 90px;
    height: 90px;
  }

  &__title {
    color: #909099;
    line-height: 20px;
    font-weight: 500;
  }
}
</style>
