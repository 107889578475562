<script setup lang="ts">
const {
  values,
  title
} = defineProps({
  values: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    required: false,
    default: null
  }
})
</script>

<template>
  <div class="block-info">
    <div class="block-info__header flex items-center justify-between font-bold"
         v-if="title"
    >
      <h6 class="block-info__title">{{ title }}</h6>
      <button class="ocm-btn ocm-btn--icon">
        <img src="/images/icon-edit.svg"
             alt=""
        />
      </button>
    </div>

    <ul class="block-info__list flex gap-4">
      <li
          v-for="value in values"
          :key="value.key"
          class="flex items-center justify-between"
      >
        <span class="block-info__prop">
          {{ value.prop }}
        </span>
        <span class="block-info__value">
          {{ value.value }}
        </span>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.block-info {
  &__header {
    margin-bottom: 20px;
    padding: 12px 16px;
    border-radius: var(--spacing-xs, 8px);
    background: var(--Blue-08, #F2F3FF);
  }

  &__title {
    color: #000;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.4px;
  }

  &__list {
    flex-direction: column;
  }

  &__prop {
    color: #78787F;
    font-size: 16px;
    font-weight: 500;
  }

  &__value {
    color: #1D1D1F;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
  }
}
</style>
