<script setup lang="ts">
const {onCloseModal} = defineProps({
  onCloseModal: {
    type: Function,
    required: false
  }
});
</script>

<template>
  <div class="modal-restore-content">
    <p class="modal-restore-content__text">
      Вы действительно хотите возобновить клиента?
    </p>

    <div class="flex items-center gap-1">
      <span class="modal-restore-content__id">#ID010101</span>
      <router-link
          to="/"
          target="_blank"
      >
        Иванов Олег Иванович
      </router-link>
    </div>

    <div class="flex items-center gap-2 modal-restore-content__footer">
      <button class="ocm-btn"
              @click="onCloseModal"
      >
        Возобновить
      </button>
      <button class="ocm-btn"
              @click="onCloseModal"
      >
        Отмена
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-restore-content {
  &__text {
    margin: 24px 0;
    font-size: 18px;
  }

  &__id {
    color: #909099;
    font-size: 16px;
    font-weight: 500;
  }

  &__footer {
    margin-top: 32px;
  }
}
</style>
