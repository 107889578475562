<script setup lang="ts">

</script>

<template>
  <form class="template-card-event">
    <div class="template-card-event__header">
      <img
          class="template-card-event__img"
          src="/images/img-template-event.svg"
          alt=""
      />
    </div>

    <h6 class="template-card-event__title mb-1">
      Не пропустите
    </h6>

    <p class="template-card-event__text">
      22.12.2023 мы проводим вебинар на тему «Первичные головные боли, дифференциальная диагностика, подходы к терапии».
      Успейте зарегистрироваться сейчас и получите бонусы.
    </p>

    <div class="my-2">
      <input class="template-card-event__email"
             type="email"
             placeholder="Введите e-mail"
      />
    </div>

    <button
        class="template-card-event__send ocm-btn"
        type="submit"
    >
      Зарегистрироваться
    </button>
  </form>
</template>

<style scoped lang="scss">
.template-card-event {
  width: 188px;
  padding: 8px;
  background: #fff;

  &__img {
    margin-bottom: 8px;
    border-radius: 8px;
  }

  &__title {
    color: #000;
    font-size: 10px;
    font-weight: 600;
  }

  &__text {
    font-size: 7px;
    font-weight: 500;
    line-height: 9px;
  }

  &__email {
    height: 20px;
    width: 100%;
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid #C0C0CC;
    color: #A8A8B2;
    font-weight: 500;
    font-size: 6px;
  }

  &__send {
    width: 100%;
    padding: 1px 2px;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
    font-size: 6px;
    background: #474EFF;

    &:hover, &:active {
      background: #151CD6;
    }
  }
}
</style>
