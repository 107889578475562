import {INavItem} from '@/interfaces/Navigation';

export const navigationSections:INavItem[] = [
    {
        path: '/clients',
        name: 'Клиенты',
        icon: '/images/icon-nav-clients.svg',
        iconEffect: '/images/icon-nav-clients-effect.svg'
    },
    {
        path: '/segments',
        name: 'Сегменты',
        icon: '/images/icon-nav-segments.svg',
        iconEffect: '/images/icon-nav-segments-effect.svg'
    },
    {
        path: '/companies',
        name: 'Кампании',
        icon: '/images/icon-nav-companies.svg',
        iconEffect: '/images/icon-nav-companies-effect.svg'
    }
]
