<script setup lang="ts">
import { debounce } from 'vue-debounce'
import {ref} from 'vue';

const {onChangeText} = defineProps({
  onChangeText: {
    type: Function,
    required: true
  }
});

const text = ref<string>('');

const search = debounce(val => {
  onChangeText(text.value);
}, '600ms')
</script>

<template>
  <div class="cell-search flex justify-end">
    <img class="cell-search__icon"
         src="/images/icon-search.svg"
         alt=""
    />
    <input class="cell-search__input"
           placeholder="Поиск"
           @input="search"
           v-model="text"
           type="text"
    />
  </div>
</template>

<style scoped lang="scss">
.cell-search {
  position: relative;
  width: 80px;

  &__icon {
    position: absolute;
    top: 10px;
    right: 4px;
    height: 15px;
    width: 15px;
  }

  &__input {
    max-width: 100px;
    padding: 8px 28px 8px 8px;
    color: #474EFF;
    font-weight: 600;
    line-height: 20px;
    text-align: right;
  }
}
</style>
