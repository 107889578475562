<script setup lang="ts">
import {useTableStore} from "@/store/table";

import OCMDropDown from "@/components/drop-down/OCMDropDown.vue";

const tableStore = useTableStore();

const handleSelectAll = () => {
  tableStore.toggleSelectAll();
}
</script>

<template>
  <div class="ocm-table-top flex justify-between gap-4">
    <div class="flex items-center justify-between gap-4 ocm-table-top__left">
      <div class="ocm-table-top__statistics">
        <span class="ocm-table-top__statistics__value font-bold ocm-font-bold">100</span> найдено / <span class="ocm-table-top__statistics__value font-bold ocm-font-bold">0</span> выбрано
      </div>

      <button class="ocm-table-top__select_all ocm-btn ocm-btn--link"
              @click="handleSelectAll"
      >
        Выбрать все
      </button>
    </div>

    <div class="ocm-table-top__right flex justify-end">
      <OCMDropDown />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/config';

.ocm-table-top {
  &__left {
    padding: 0 20px;
  }

  &__statistics {
    color: $colorGrey;

    &__value {
      color: #AD17E5;
    }
  }
}
</style>
