<script setup lang="ts">
import {PropType} from 'vue';
import type {IEventAction} from '@/interfaces/Events';

const {
  data
} = defineProps({
  data: {
    type: Object as PropType<IEventAction>,
    required: true
  }
})
</script>

<template>
  <div class="action-item-info">
    <div class="action-item-info__top">
      <div class="flex gap-3">
        <div class="flex gap-1 items-center">
          <img src="/images/icon-clock.svg"
               alt=""
          />
          <span class="action-item-info__date">{{ data.date }}</span>
        </div>
        <div>
          <span class="action-item-info__place">
            {{ data.place }}
          </span>
        </div>
      </div>
    </div>
    <div class="action-item-info__text">
      {{ data.text }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.action-item-info {
  padding: 10px 0;

  &__date {
    color: #A8A8B2;
    font-size: 14px;
    font-weight: 500;
  }

  &__place {
    color: #09B344;
  }

  &__text {
    color: #1D1D1F;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
  }
}
</style>
