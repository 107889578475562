<script setup lang="ts">
import type {IEventAction} from '@/interfaces/Events';
import {PropType} from "vue";

import ActionItemInfo from "@/components/client-right-aside/actions/ActionItemInfo.vue";

const {
  item
} = defineProps({
  item: {
    type: Object as PropType<IEventAction>,
    required: true
  }
});
</script>

<template>
  <div class="block-actions-item">
    <div class="block-actions-item__grid grid">
      <div class="block-actions-item__icon flex items-center justify-center">
        <img :src="item.icon"
             alt=""
             loading="lazy"
        />
      </div>

      <div class="block-actions-item__info">
        <ActionItemInfo :data="item" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$iconSize: 64px;

.block-actions-item {
  &__grid {
    grid-template-columns: 64px calc(100% - ($iconSize - 12px));
    gap: 12px;
  }

  &__icon {
    height: $iconSize;
    width: $iconSize;
    border-radius: 8px;
    background: #F7F8FF;

    & img {
      height: 32px;
      width: 32px;
    }
  }
}
</style>
