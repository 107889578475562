<template>
  <div class="cell-details">
    <router-link
        to="/"
        class="cell-details__link"
    >Подробнее</router-link>
  </div>
</template>

<style scoped lang="scss">
.cell-details {
  &__link {
    color: #1a20ff;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
<script setup lang="ts">
</script>
