<script setup lang="ts">

</script>

<template>
  <div class="cell-status flex gap-1 items-center">
    <span class="cell-status__circle"></span>
    <span class="cell-status__name">
      Переход
    </span>
  </div>
</template>

<style scoped lang="scss">
.cell-status {
  &__circle {
    position: relative;
    top: -1px;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: #09B344;
  }

  &__name {
    font-weight: 500;
  }
}
</style>
