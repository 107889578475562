<script setup lang="ts">
import {ref} from 'vue';

import BlockInfoColor from "@/components/block-info/BlockInfoColor.vue";
import CompanyInfo from "@/components/company/CompanyInfo.vue";
import CRMTabs from "@/components/tabs/CRMTabs.vue";
import CompanyGeneral from "@/components/company/CompanyGeneral.vue";
import CompanyClients from "@/components/company/CompanyClients.vue";
import CompanyClicks from '@/components/company/CompanyClicks.vue';
import CompanyGoals from "@/components/company/CompanyGoals.vue";

import type {IBlockInfoItem} from "@/interfaces/Blocks";
import type { ITab } from '@/interfaces/Tabs'
import CompanyLinks from "@/components/company/CompanyLinks.vue";

const statistics:IBlockInfoItem[] = [
  {
    prop: 'Отправлено',
    value: '297'
  },
  {
    prop: 'Доставлено',
    value: '285'
  },
  {
    prop: 'Прочитано',
    value: '200'
  },
  {
    prop: 'Переходов',
    value: '180'
  },
  {
    prop: 'Ошибок',
    value: '14'
  },
];
const tabs:ITab[] = [
  {
    key: 'general',
    name: 'Общее'
  },
  {
    key: 'clients',
    name: 'Список клиентов'
  },
  {
    key: 'statistics',
    name: 'Статистика'
  },
  {
    key: 'clicks',
    name: 'Карта кликов'
  },
  {
    key: 'goals',
    name: 'Целевые действия'
  },
  {
    key: 'mailing',
    name: 'Связанные рассылки'
  }
];

const view = ref<string>('general');

const handleSelect = (tab:ITab) => view.value = tab.key;
</script>

<template>
  <div class="company">
    <div class="company__header flex justify-between">
      <div class="company__header__left flex items-center gap-2">
        <router-link
            class="ocm-btn ocm-btn--icon company__back"
            to="/companies"
        >
          <img src="/images/arrow-back.svg"
               alt=""
          />
        </router-link>
        <h2 class="company__header__title">Профиль кампании</h2>
      </div>

      <div class="company__control flex gap-3 items-center">
        <button class="ocm-btn ocm-btn--white">
          Запустить повторно
        </button>
        <button class="ocm-btn">
          Действия
        </button>
      </div>
    </div>

    <div class="company__info">
      <CompanyInfo />
    </div>

    <div class="company__block-info">
      <div class="company__block-info__content flex gap-3">
        <div v-for="item in statistics"
             :key="item.prop"
        >
          <BlockInfoColor
              :prop="item.prop"
              :value="item.value"
          />
        </div>
      </div>
    </div>

    <div class="company__tabs">
      <CRMTabs
          :options="tabs"
          :onSelect="handleSelect"
      />
    </div>

    <div class="company__content">
      <CompanyGeneral v-if="view === 'general'" />
      <CompanyClients v-if="view === 'clients'" />
      <CompanyClicks v-if="view === 'clicks'" />
      <CompanyGoals v-if="view === 'goals'" />
      <CompanyLinks v-if="view === 'mailing'" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.company {
  &__back {
    position: relative;
    top: -2px;
    height: 24px;
    width: 24px;
    border-radius: 8px;
    background: #E4E4F2;
  }

  &__header {
    margin-bottom: 24px;

    &__title {
      color: #1D1D1F;
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__info {
    margin-bottom: 32px;
  }

  &__block-info {
    margin-bottom: 40px;
  }

  &__content {
    margin-bottom: 40px;
  }
}
</style>
