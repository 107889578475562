<script setup lang="ts">
import {ref} from 'vue';

import ModalRestoreContent from "@/components/clients-archive/ModalRestoreContent.vue";

import { ReloadOutlined, DeleteOutlined } from '@ant-design/icons-vue';

const isShowModal = ref<boolean>(false);

const handleRestore = () => {
  isShowModal.value = true;
}

const handleCloseModal = () => {
  isShowModal.value = false;
}
</script>

<template>
  <div>
    <div class="cell-table-control flex gap-2 items-center">
      <button class="ocm-btn ocm-btn--icon cell-table-control__restore"
              @click="handleRestore"
      >
        <ReloadOutlined />
      </button>
      <button class="ocm-btn ocm-btn--icon cell-table-control__remove">
        <DeleteOutlined />
      </button>
    </div>

    <a-modal v-model:open="isShowModal"
             title="Возобновление клиента"
    >
      <template #footer>
        <div></div>
      </template>

      <ModalRestoreContent
          :onCloseModal="handleCloseModal"
      />
    </a-modal>
  </div>
</template>

<style scoped lang="scss">
.cell-table-control {
  &__remove {
    color: #F64241;
  }

  &__restore {
    color: #474EFF;
  }
}
</style>
