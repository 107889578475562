<script setup lang="ts">
import {ref} from 'vue';

import ModalCreateSegment from "@/components/clients/ModalCreateSegment.vue";

const isShowModal = ref<boolean>(false);

const handleShowModal = () => {
  isShowModal.value = true;
}

const handleCloseModal = () => {
  isShowModal.value = false;
}
</script>

<template>
  <div class="clients-filter-control flex gap-2 justify-end flex-wrap">
    <button class="ocm-btn">
      Сформировать сегмент обновления
    </button>
    <button class="ocm-btn"
            @click="handleShowModal"
    >
      Сформировать сегмент
    </button>

    <a-modal v-model:open="isShowModal"
             title="Формирование сегмента обновлений"
             width="620px"
    >
      <template #footer>
        <div></div>
      </template>
      <ModalCreateSegment
          :closeModal="handleCloseModal"
      />
    </a-modal>
  </div>
</template>

<style scoped lang="scss">
.clients-filter-control {

}
</style>
