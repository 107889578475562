<script setup lang="ts">

</script>

<template>
  <div class="header-notification flex items-center">
    <img src="/images/icon-noty.svg"
         alt="Notification"
    />

    <div class="header-notification__counter">
      3
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-notification {
  position: relative;

  &__counter {
    position: absolute;
    top: 5px;
    right: -11px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF2E62;
  }
}
</style>
