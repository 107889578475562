<script setup lang="ts">
import type {IOptionWithComponent} from "@/interfaces/Options";

const {
  options,
  title,
  img
} = defineProps({
  options: {
    type: Array as () => Array<IOptionWithComponent>,
    required: false,
    default: []
  },
  title: {
    type: String,
    required: true
  },
  img: {
    type: String,
    required: false,
    default: null
  }
})
</script>

<template>
  <div class="company-general-card">
    <div class="company-general-card__header flex gap-3 items-center">
      <div class="company-general-card__header__img flex justify-center items-center">
        <img :src="img"
             alt=""
             v-if="img"
        />
      </div>
      <h5 class="company-general-card__header__title">
        {{ title }}
      </h5>
    </div>

    <ul class="company-general-card__list">
      <li
          v-for="option in options"
          :key="option.prop"
          class="flex gap-3 items-center"
      >
        <span class="company-general-card__list__prop">
          {{ option.prop }}
        </span>
        <span class="company-general-card__list__value">
          <template v-if="option.isComponent">
            <component :is="option.value" />
          </template>

          <template v-if="!option.isComponent">
            {{ option.value }}
          </template>
        </span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.company-general-card {
  width: 489px;
  height: 328px;
  padding: 24px;
  border: 1px solid #F2F3FF;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px 0 rgba(66, 58, 166, 0.08);

  &__header {
    margin-bottom: 24px;

    &__img {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background: linear-gradient(136deg, #B466FF 4.44%, #8F19FF 94.45%);

      & img {
        height: 28px;
        width: 28px;
        object-fit: cover;
      }
    }

    &__title {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.4px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__prop,
    &__value {
      font-weight: 500;
    }

    &__prop {
      color: #78787F;
      letter-spacing: 0.16px;
    }

    &__value {
      color: #1D1D1F;
    }
  }

  & .value-component {
    &__link {
      color: #474EFF;

      &:hover, &:active {
        color: #151CD6;
      }
    }

    &__img {
      position: relative;
      top: -2px;
    }
  }
}
</style>
