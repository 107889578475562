<script setup lang="ts">
import {Component} from 'vue';

import OCMTableTop from "@/components/tables/OCMTableTop.vue";
import CellSearch from "@/components/tables/CellSearch.vue";

import {IThead, ITableSettings} from '@/interfaces/Table';

const {listTHead, data, settings, search} = defineProps({
  listTHead: {
    type: Array as () => Array<IThead>,
    required: false,
    default: []
  },
  data: {
    type: Array as () => Array<any>,
    required: false,
    default: []
  },
  settings: {
    type: Object as () => ITableSettings,
    required: false,
    default: null
  },
  search: {
    type: Function,
    required: false,
  }
});

const handleTextChange = (text:string) => {
  if(search) {
    search(text);
  }
}
</script>

<template>
  <div class="ocm-table-default">
    <div class="ocm-table-default__top">
      <OCMTableTop />
    </div>

    <div class="ocm-table-default__wrap-table">
      <table class="ocm-table-default__table">
        <thead>
        <tr>
          <th
              v-for="th in listTHead"
              :key="th.key"
              :class="th.thClass ? th.thClass : ''"
          >
            <span v-if="th.key !== 'control'">
              {{ th.label }}
            </span>
            <span v-if="th.key === 'control'">
              <CellSearch
                  :onChangeText="handleTextChange"
              />
            </span>
          </th>
        </tr>
        </thead>

        <tbody v-if="data[0]">
        <tr v-for="(item,index) in data"
            :key="item.id"
        >
          <td v-for="prop in listTHead"
              :key="`td-${index}-${prop.key}`"
          >
            <div v-if="!prop.content">
              {{ item[prop.key]}}
            </div>
            <div v-else>
              <Component
                  :is="prop.content"
                  :data="item"
              />
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div v-if="settings && settings.empty && !data[0]"
           class="ocm-table-default__empty"
      >
        <Component
            :is="settings.empty"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ocm-table-default {
  &__top {
    margin-bottom: 12px;
  }

  &__wrap-table {
    overflow: auto;
  }

  &__table {
    width: 100%;
    border-radius: 12px;

    & th,
    & td {
      text-align: left;
    }

    & th {
      min-width: 24ch;
      padding: 12px;
      border-bottom: 1px solid #E4E4F2;
      color: #A8A8B2;
      font-size: 14px;
      font-weight: 600;

      &.thin {
        min-width: 10ch;
      }

      &.th-control {
        width: 80px;
        max-width: 80px;
        min-width: 80px;

        & > span {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    & td {
      color: #1D1D1F;
      padding: 12px;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      background: #fff;
    }
  }

  &__empty {
    padding: 230px 0;
    border: 1px solid var(--gray-scale-20, #E4E4F2);
    box-shadow: 0 8px 20px -20px rgba(229, 230, 242, 0.25);
    background: #FFF;
  }
}
</style>
