<script setup lang="ts">
import BlockInfo from "@/components/clients-update/BlockInfo.vue";

import {IClientsContact} from "@/interfaces/Clients";

const {
    title,
    blocks
} = defineProps({
  title: {
    type: String,
    required: true
  },
  blocks: {
    type: Array as PropType<IClientsContact>,
    required: false,
    default: []
  }
})
</script>

<template>
  <div class="card-info">
    <h5 class="card-info__title">{{ title }}</h5>
    <div class="card-info__content flex gap-4">
      <BlockInfo
        v-for="block in blocks"
        :key="block.title"
        :data="block"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-info {
  margin-bottom: 2rem;

  &__title {
    margin-bottom: 1rem;
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }

  &__content {
    flex-wrap: wrap;
  }
}
</style>
