<script setup lang="ts">
import {ref} from 'vue';

import NavigationTabs from "@/components/navigation/NavigationTabs.vue";
import BlockGeneral from "@/components/client-right-aside/BlockGeneral.vue";
import BlockActions from "@/components/client-right-aside/BlockActions.vue";
import BlockEvents from "@/components/client-right-aside/events/BlockEvents.vue";

import type {INavTab} from "@/interfaces/Navigation";

const view = ref<string>('');
const tabs:INavTab[] = [
  {
    key: 'general',
    label: 'Общее'
  },
  {
    key: 'actions',
    label: 'Действия'
  },
  {
    key: 'events',
    label: 'Мероприятия'
  },
  // {
  //   key: 'update',
  //   label: 'Настройка обновлений'
  // }
];

const handleChangeTab = (tab:INavTab) => {
  view.value = tab.key;
}
</script>

<template>
  <aside class="client-right-aside">
    <div class="client-right-aside__tabs">
      <NavigationTabs
          :tabs="tabs"
          :onChangeTab="handleChangeTab"
      />
    </div>

    <div class="client-right-aside__content">
      <BlockGeneral
        v-if="view === '' || view === 'general'"
      />
      <BlockActions
        v-if="view === 'actions'"
      />
      <BlockEvents v-if="view === 'events'" />
    </div>
  </aside>
</template>

<style scoped lang="scss">
.client-right-aside {
  &__tabs {
    margin-bottom: 40px;
  }
}
</style>
