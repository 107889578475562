<script setup lang="ts">
import {ref} from 'vue';

import PaginationDefault from "@/components/pagination/PaginationDefault.vue";
import TableAdaptiveHeight from "@/components/tables/TableAdaptiveHeight.vue";
import CRMLoader from "@/components/loader/CRMLoader.vue";
import OCMTableDefault from "@/components/tables/OCMTableDefault.vue";
import {IThead} from "@/interfaces/Table";

const items = [
  {
    id: '444444',
    typeMailing: 'E-mail',
    name: 'Отчёт по числу регистраций за ноябрь в Самаре',
    dateCreate: '23.11.2023 в 12:32:12',
    viewType: 'Агрегированный',
    status: 'Оригинал'
  },
  {
    id: '333333',
    typeMailing: 'E-mail',
    name: 'Отчёт по рассылке приглашений в Краснодаре',
    dateCreate: '23.11.2023 в 12:32:12',
    viewType: 'Агрегированный',
    status: 'Оригинал'
  },
  {
    id: '222222',
    typeMailing: 'E-mail',
    name: 'Промежуточный отчёт по посещению мероприятий в ...',
    dateCreate: '17.10.2023 в 9:00:10',
    viewType: 'Агрегированный',
    status: 'Оригинал'
  },
  {
    id: '111111',
    typeMailing: 'E-mail',
    name: 'Отчёт по полезной информации для терапевтов',
    dateCreate: '15.10.2023 в 10:32:18',
    viewType: 'Обычный',
    status: 'Оригинал'
  }
];
const isLoading = ref<boolean>(false);
const listTHead: IThead[] = [
  {
    key: 'id',
    label: '№'
  },
  {
    key: 'typeMailing',
    label: 'Тип рассылки'
  },
  {
    key: 'name',
    label: 'Название'
  },
  {
    key: 'dateCreate',
    label: 'Дата создания'
  },
  {
    key: 'viewType',
    label: 'Вид отчёта'
  },
  {
    key: 'status',
    label: 'Статус'
  }
];

const handleChangeCurrentPage = () => {
  console.log('handle');
}
</script>

<template>
  <div class="companies-reports">
    <CRMLoader v-if="isLoading"/>
    <div v-else>
      <TableAdaptiveHeight>
        <OCMTableDefault
            :listTHead="listTHead"
            :data="items"
        />
      </TableAdaptiveHeight>

      <div class="companies__pagination">
        <PaginationDefault
            :currentPage="1"
            :onChangePage="handleChangeCurrentPage"
            :countPages="3"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
