<script setup lang="ts">

</script>

<template>
  <div class="cell-link">
    <a class="cell-link__link"
       href="https://aknaucer.ru/schedule/74803/"
       target="_blank"
    >
      https://aknaucer.ru/schedule/74803/
    </a>
  </div>
</template>

<style scoped lang="scss">
.cell-link {
  &__link {
    color: #4046FF;

    &:hover, &:active {
      color: #1A21FF;
    }
  }
}
</style>
