<script setup lang="ts">
import ClientsUpdateAlert from "@/components/clients-update/ClientsUpdateAlert.vue";
import CardInfo from './CardInfo.vue';

import {IClientsContact} from "@/interfaces/Clients";

const contactBlockInfo:IClientsContact[] = [
  {
    title: 'Основной номер телефона',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Второй номер телефона',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Основная почта',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Вторая почта',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Telegram',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'WhatsApp',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Viber',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'VK',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Однокласники',
    matches: 200,
    available_updates: 13
  }
];
const addressBlockInfo:IClientsContact[] = [
  {
    title: 'Регион',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Город',
    matches: 200,
    available_updates: 13
  }
];
const profBlockInfo:IClientsContact[] = [
  {
    title: 'Специальность',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Акредитация',
    matches: 200,
    available_updates: 13
  },
  {
    title: 'Место работы',
    matches: 200,
    available_updates: 13
  }
];
</script>

<template>
  <div class="clients-update">
    <div class="clients-update__alert">
      <ClientsUpdateAlert />
    </div>

    <div class="clients-update__info">
      <div class="clients-update__info__header">
        <h4 class="clients-update__info__title font-bold mb-1">
          Результаты сравнения
        </h4>
        <h6 class="clients-update__info__subtitle">
          <span class="clients-update__info__subtitle__count ocm-font-bold">992</span>
          профилей сравнили
        </h6>
      </div>

      <ul class="clients-update__info__blocks">
        <li>
          <CardInfo
              title="Контактная информация"
              :blocks="contactBlockInfo"
          />
        </li>
        <li>
          <CardInfo
              title="Местоположение"
              :blocks="addressBlockInfo"
          />
        </li>
        <li>
          <CardInfo
              title="Профессиональная информация"
              :blocks="profBlockInfo"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.clients-update {
  &__alert {
    margin-bottom: 40px;
  }

  &__info {
    &__header {
      margin-bottom: 1.5rem;
    }

    &__title {
      margin-bottom: 1rem;
      font-size: 20px;
      color: #000;
    }

    &__subtitle {
      color: #CCCCCC;
      font-weight: 700;

      &__count {
        color: #ad17e5;
      }
    }
  }

  &__blocks {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }
}
</style>
