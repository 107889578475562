<script setup lang="ts">
const {
  prop,
  value
} = defineProps({
  prop: {
    type: String,
    required: true,
  },
  value: {
    type: [String,Number,Boolean],
    required: true
  }
})
</script>

<template>
  <div class="block-info-color">
    <h6 class="block-info-color__prop">
      {{ prop }}
    </h6>
    <h6 class="block-info-color__value">
      {{ value }}
    </h6>
  </div>
</template>

<style scoped lang="scss">
.block-info-color {
  height: 64px;
  padding: 8px 16px;
  border-radius: 12px;
  overflow: hidden;
  background: #F7F8FF;

  &__prop,
  &__value {
    font-size: 16px;
    letter-spacing: 0.16px;
  }

  &__prop {
    margin-bottom: .2rem;
    color: #909099;
    font-weight: 500;
  }

  &__value {
    color: #474EFF;
    font-weight: 700;
  }
}
</style>
