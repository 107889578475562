<script lang="ts" setup>
import {watch, ref} from 'vue'
import { useRoute } from 'vue-router'

import AppHeaderUser from "@/components/header/AppHeaderUser.vue";
import HeaderNotification from "@/components/header/HeaderNotification.vue";

const router = useRoute();
const title = ref<string>('Клиенты');

watch(router, (value) => {
  switch (value.name) {
    case 'segments':
      title.value = 'Сегменты';
      break;

    case 'companies':
      title.value = 'Компании';
      break;

    default:
      title.value = 'Клиенты'
      break;
  }
})
</script>

<template>
  <header class="header-default flex items-center justify-between">
    <h5 class="header-default__page-name font-bold">
      {{ title }}
    </h5>

    <div class="header-default__right flex">
      <HeaderNotification />
      <AppHeaderUser />
    </div>
  </header>
</template>

<style scoped lang="scss">
.header-default {
  height: 80px;
  padding: 0 48px 0 60px;
  border-bottom: 1px solid #D8D8E5;

  &__page-name {
    color: #1D1D1F;
    font-size: 24px;
  }

  &__right {
    gap: 40px;
  }
}
</style>
