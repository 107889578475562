<script setup lang="ts">
</script>

<template>
  <div class="company-general-segment value-component flex gap-2 items-center">
    <router-link
        class="company-general-segment__link value-component__link"
        to="/"
    >Врачи из Краснодара</router-link>
    <img src="/images/circle-arrow.svg"
         alt=""
         class="company-general-segment__img value-component__img"
    />
  </div>
</template>
