<script setup lang="ts">
import type {IOption} from '@/interfaces/Options'

const options:IOption[] = [
  {
    prop: 'Тип рассылки:',
    value: 'E-mail'
  },
  {
    prop: 'Партнёр:',
    value: 'ООО «Ригла»'
  },
  {
    prop: 'Дата отправки:',
    value: '09.11.2023'
  },
];
</script>

<template>
  <div class="company-info">
    <div class="company-info__header flex items-center gap-1">
      <h6 class="company-info__title">
        Бонусы за вебинар
      </h6>
      /
      <button class="ocm-btn ocm-btn--link">
        Изменить название
      </button>
    </div>

    <ul class="company-info__options">
      <li class="flex items-center gap-1"
          v-for="option in options"
          :key="option.prop"
      >
        <span class="company-info__options__prop">
          {{ option.prop }}
        </span>
        <span class="company-info__options__value">
          {{ option.value }}
        </span>
      </li>
    </ul>

    <div class="company-info__status">
      Завершена
    </div>
  </div>
</template>

<style scoped lang="scss">
.company-info {
  &__header {
    margin-bottom: 20px;
    color: #C0C0CC;
  }

  &__title {
    color: #1D1D1F;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.4px;
  }

  &__options {
    margin-bottom: 24px;
    display: flex;
    gap: 24px;
    flex-direction: column;

    &__prop,
    &__value {
      color: #78787F;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &__value {
      color: #1D1D1F;
    }
  }

  &__status {
    padding: 4px 16px;
    border-radius: 120px;
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    display: inline-block;
    background: #EE307D;
  }
}
</style>
