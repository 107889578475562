<script setup lang="ts">
import {ref} from 'vue';

import TabsButtons from "@/components/tabs/TabsButtons.vue";
import BlockEventVisited from "@/components/client-right-aside/events/BlockEventVisited.vue";

import {ITableAction} from "@/interfaces/Tabs";

const tabs:ITableAction[] = [
  {key: 'portal', name: 'Действия на портале'},
  {key: 'resources', name: 'Посещенные ресурсы'},
  {key: 'registration', name: 'Зарегистрирован'},
  {key: 'processing', name: 'В процессе'},
  {key: 'checked', name: 'Пройдены'}
];
const activeView = ref<string>('');

const handleChangeView = (key:string) => {
  activeView.value = key;
}
</script>

<template>
  <div class="block-events">
    <div class="block-events__tabs mb-6">
      <TabsButtons
          :change="handleChangeView"
          :tabs="tabs"
      />
    </div>

    <div class="block-events__content">
      <BlockEventVisited />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
