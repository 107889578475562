<script setup lang="ts">
import type {ICompanyGeneralCard} from "@/interfaces/Company";

import CompanyGeneralCard from "@/components/company/CompanyGeneralCard.vue";
import CompanyGeneralSegment from "@/components/company/CompanyGeneralSegment.vue";
import CompanyGeneralFilter from "@/components/company/CompanyGeneralFilter.vue";
import CompanyGeneralTemplate from "@/components/company/CompanyGeneralTemplate.vue";

const cards:ICompanyGeneralCard[] = [
  {
    title: 'Получатели',
    img: '/images/company-card-users.svg',
    options: [
      {
        prop: 'Получатели:',
        value: 'По сегменту',
        isComponent: false
      },
      {
        prop: 'Сегмент:',
        value: CompanyGeneralSegment,
        isComponent: true
      },
      {
        prop: 'Фильтр:',
        value: CompanyGeneralFilter,
        isComponent: true
      },
      {
        prop: 'Попадание в сегмент:',
        value: 'Да',
        isComponent: false
      },
      {
        prop: 'Количество контактов:',
        value: '297',
        isComponent: false
      },
      {
        prop: 'Стоимость:',
        value: '2 000 ₽',
        isComponent: false
      }
    ]
  },
  {
    title: 'Сообщение',
    img: '/images/company-card-mail.svg',
    options: [
      {
        prop: 'Шаблон:',
        value: CompanyGeneralTemplate,
        isComponent: true
      }
    ]
  },
  {
    title: 'Дата и время',
    img: '/images/company-card-date-time.svg',
    options: [
      {
        prop: 'Отправка:',
        value: 'Запланировано',
        isComponent: false
      },
      {
        prop: 'Дата старта:',
        value: '09.11.2023',
        isComponent: false
      },
      {
        prop: 'Время отправки:',
        value: '8:00',
        isComponent: false
      },
      {
        prop: 'Отправить по метке UTC:',
        value: 'Да',
        isComponent: false
      }
    ]
  }
];
</script>

<template>
  <div class="company-general">
    <div class="company-general__cards flex gap-3 flex-wrap">
      <div
        v-for="card in cards"
        :key="card.title"
        class="company-general__card"
      >
        <CompanyGeneralCard
            :title="card.title"
            :options="card.options"
            :img="card.img"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.company-general {
  &__cards {
    gap: 12px;
  }

  &__card {
    margin-bottom: 1rem;
  }
}
</style>
