<script setup lang="ts">

</script>

<template>
  <div class="template-card-message">
    <div class="template-card-message__header flex items-center justify-between mb-2">
      <div class="flex gap-1">
        <img src="/images/icon-message.svg"
             class="template-card-message__header__img"
        />
        <span class="template-card-message__header__title">Сообщение</span>
      </div>

      <span class="template-card-message__header__time">
        сейчас
      </span>
    </div>

    <div class="template-card-message__title mb-2">
      Акнауцер
    </div>

    <p class="template-card-message__message">
      [Имя] [Отчество], 22.12.2023 мы проводим вебинар на тему “Первичные головные боли, дифференциальная диагностика, подходы к терапии”. Успейте зарегистрироваться сейчас и получите бонусы. Регистрация по ссылке https://aknaucer.ru
    </p>
  </div>
</template>

<style scoped lang="scss">
.template-card-message {
  padding: 8px 12px;
  border-radius: 13px;
  background: #fff;

  &__header {
    font-size: 12px;

    &__img {

    }

    &__title {
      color: #000;
      font-weight: 600;
    }

    &__time {
      color: #A8A8B2;

    }
  }

  &__title {
    font-weight: 600;
  }

  &__message {
    color: #000;
    font-size: 12px;
  }
}
</style>
