<script setup lang="ts">
import {useFilterStore} from "@/store/filters";

import FilterAsideBlock from '@/components/filters/filter-aside/FitlerAsideBlock.vue';
import FilterAsideBlockPersonal from "@/components/filters/filter-aside/blocks/FilterAsideBlockPersonal.vue";
import FilterAsideBlockEvents from "@/components/filters/filter-aside/blocks/FilterAsideBlockEvents.vue";
import FilterAsideBlockPlaces from "@/components/filters/filter-aside/blocks/FilterAsideBlockPlaces.vue";
import FilterAsideBlockContacts from "@/components/filters/filter-aside/blocks/FilterAsideBlockContacts.vue";
import FilterAsideBlockSpecialization from "@/components/filters/filter-aside/blocks/FilterAsideBlockSpecialization.vue";

import {IFilterAside} from "@/interfaces/Filters";

const filterStore = useFilterStore();
const items:IFilterAside[] = [
  {
    key: 'personal',
    content: FilterAsideBlockPersonal,
    title: 'Персональные данные'
  },
  {
    key: 'contacts',
    content: FilterAsideBlockContacts,
    title: 'Контактные данные'
  },
  {
    key: 'place',
    content: FilterAsideBlockPlaces,
    title: 'Местоположение'
  },
  {
    key: 'prof',
    content: FilterAsideBlockSpecialization,
    title: 'Профессиональная информация'
  },
  {
    key: 'events',
    content: FilterAsideBlockEvents,
    title: 'Мероприятия'
  }
];

const shadowClick = () => {
  filterStore.closeAsideMenu();
}

const handleCancel = () => {
  filterStore.closeAsideMenu();
}
</script>

<template>
  <div class="filter-aside-block-shadow"
       @click="shadowClick"
  ></div>

  <div class="filter-aside">
    <h5 class="filter-aside__title">
      Фильтр
    </h5>

    <div class="filter-aside__content flex">
      <FilterAsideBlock
        v-for="item in items"
        :key="item.key"
        :title="item.title"
      >
        <Component :is="item.content" />
      </FilterAsideBlock>
    </div>

    <div class="filter-aside__footer grid grid-cols-2 gap-4">
      <button class="ocm-btn ocm-btn"
              @click="handleCancel"
      >
        Применить
      </button>
      <button class="ocm-btn ocm-btn--secondary"
              @click="handleCancel"
      >
        Отменить
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.filter-aside {
  position: absolute;
  top: 0;
  right: 0;
  width: 647px;
  height: 100%;
  z-index: 100;
  padding: 60px 32px 0 32px;
  border-radius: var(--spacing-xss, 12px) 0 0 var(--spacing-xss, 12px);
  overflow: auto;
  background: #fff;

  &__content {
    flex-direction: column;
    gap: 32px;
  }

  &__title {
    margin-bottom: 28px;
    padding-left: 12px;
    font-size: 24px;
    font-weight: 700;
  }

  &__label {
    display: block;
    color: #1D1D1F;
    font-size: 16px;
    font-weight: 600;
  }

  &__footer {
    margin-top: 28px;
    padding: 24px 0;
  }
}

.filter-aside-block-shadow {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.70);
  overflow: hidden;
}

.filter-aside-select {
  height: 56px !important;

  & .ant-select-selection-item {
    line-height: 52px !important;
  }
}
</style>
