<script setup lang="ts">
import CompanyGoalsCard from "@/components/company/CompanyGoalsCard.vue";

import type {ICompanyGoal} from "@/interfaces/Company";
import CardInfo from "@/components/clients-update/CardInfo.vue";

const cards:ICompanyGoal[] = [
  {
    title: 'Открытия',
    leftOptions: {
      title: 'Открыли',
      value: 0,
      icons: []
    },
    rightOptions: {
      title: 'Не открыли',
      value: 0,
      icons: []
    }
  },{
    title: 'Клики',
    leftOptions: {
      title: 'Кликнули',
      value: 0,
      icons: []
    },
    rightOptions: {
      title: 'Не кликнули',
      value: 0,
      icons: []
    }
  },
  {
    title: 'Переходы',
    leftOptions: {
      title: 'Перешли',
      value: 0,
      icons: []
    },
    rightOptions: {
      title: 'Не перешли',
      value: 0,
      icons: []
    }
  },
  {
    title: 'Отписки',
    leftOptions: {
      title: 'Отписались',
      value: 0,
      icons: []
    },
    rightOptions: null
  },
  {
    title: 'Действия',
    leftOptions: {
      title: 'Выполнили',
      value: 0,
      icons: []
    },
    rightOptions: {
      title: 'Не выполнили',
      value: 0,
      icons: []
    }
  }
]
</script>

<template>
  <div class="company-goals">
    <div class="company-goals__cards flex flex-wrap gap-3">
      <div
          v-for="card in cards"
          :key="card.title"
      >
        <CompanyGoalsCard
            :options="card"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
