<script setup lang="ts">
import {ref} from 'vue';

const {paramName} = defineProps({
  paramName: {
    type: String,
    required: true
  }
});

const isChecked = ref<boolean>(false);
</script>

<template>
  <div class="segment-param">
    <a-checkbox v-model:checked="isChecked">
      {{ paramName }}
    </a-checkbox>
  </div>
</template>

<style scoped lang="scss">
.segment-param {
  color: #1D1D1F;
  font-size: 16px;
  font-weight: 500;
}
</style>
