<script setup lang="ts">
import {ref} from 'vue';

const {title} = defineProps({
  title: {
    type: String,
    required: true
  }
});

const isShowContent = ref<boolean>(true);

const handleToggle = () => {
  isShowContent.value = !isShowContent.value;
}
</script>

<template>
  <div class="filter-aside-block">
    <div class="filter-aside-block__header flex items-center justify-between">
      <h6 class="filter-aside-block__title"
          @click="handleToggle"
      >
        {{ title }}
      </h6>
      <button class="filter-aside-block__toggle"
              @click="handleToggle"
      >
        <img src="/images/icon-arrow.svg"
             alt=""
             class="filter-aside-block__toggle__img"
             :class="{'show': isShowContent}"
        />
      </button>
    </div>

    <div class="filter-aside-block__content mt-6"
         v-if="isShowContent"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filter-aside-block {
  &__title {
    color: #1D1D1F;
    font-size: 18px;
    font-weight: 600;
  }

  &__toggle {
    &__img {
      transform: rotate(180deg);

      &.show {
        transform: rotate(0);
      }
    }
  }
}
</style>
