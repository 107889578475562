<script setup lang="ts">
import {ref, reactive} from 'vue';

const optionsSex:string[] = [
  'Не важно',
  'Есть',
  'Нет',
];

const radioStyle = reactive({
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
});

const valueSex = ref<string>('');
</script>

<template>
  <div class="block-personal">
    <div class="form-group">
      <label class="filter-aside__label mb-3">
        Пол
      </label>
      <a-radio-group v-model:value="valueSex">
        <a-radio
            v-for="option in optionsSex"
            :key="option"
            :style="radioStyle" :value="option">{{ option }}</a-radio>
      </a-radio-group>
    </div>
<!--    <div class="form-group">-->
<!--      <label class="filter-aside__label">-->
<!--        Дата рождения-->
<!--      </label>-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      Задать интервал-->
<!--    </div>-->
  </div>
</template>

<style scoped lang="scss">

</style>
