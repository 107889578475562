<script setup lang="ts">
import BlockActionsItem from "@/components/client-right-aside/actions/BlockActionsItem.vue";

import type {IEventAction} from '@/interfaces/Events';

const items:IEventAction[] = [
  {
    date: '16.03.2023 в 12:32:45',
    place: 'SMS',
    text: 'Рассылка',
    icon: '/images/action-icon-mail.svg'
  },
  {
    date: '12.03.2023 в 20:12:06',
    place: 'E-mail',
    text: 'Рассылка',
    icon: '/images/action-icon-mail.svg'
  },
  {
    date: '12.03.2023 в 10:06:44',
    place: 'E-mail',
    text: 'Рассылка',
    icon: '/images/action-icon-mail.svg'
  },
  {
    date: '12.03.2023 в 10:00:26',
    place: 'E-mail',
    text: 'Рассылка',
    icon: '/images/action-icon-mail.svg'
  },
  {
    date: '12.03.2023 в 10:06:44',
    place: 'Звонок',
    text: 'Рассылка',
    icon: '/images/action-icon-phone.svg'
  }
];
</script>

<template>
  <ul class="action-mailing flex gap-3">
    <li class="action-portal__item"
        v-for="item in items"
        :key="item.date"
    >
      <BlockActionsItem
          :item="item"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.action-mailing {
  flex-direction: column;
}
</style>
