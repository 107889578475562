<script setup lang="ts">
import BlockActionsItem from "@/components/client-right-aside/actions/BlockActionsItem.vue";

import type {IEventAction} from '@/interfaces/Events';

const items:IEventAction[] = [
  {
    date: '15.03.2023 в 10:12:44',
    place: 'Web',
    text: 'Регистрация на портале',
    icon: '/images/action-icon-user.svg'
  },
  {
    date: '14.03.2023 в 20:06:44',
    place: 'E-mail',
    text: 'Переход на сайт через рассылку',
    icon: '/images/action-icon-computer.svg'
  },
  {
    date: '13.03.2023 в 12:06:44',
    place: 'SMS',
    text: 'Переход на сайт через рассылку',
    icon: '/images/action-icon-computer.svg'
  }
];
</script>

<template>
  <ul class="action-portal flex gap-3">
    <li class="action-portal__item"
        v-for="item in items"
        :key="item.date"
    >
      <BlockActionsItem
          :item="item"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.action-portal {
  flex-direction: column;
}
</style>
