<script setup lang="ts">
const {
  title
} = defineProps({
  title: {
    type: String,
    required: false,
    default: null
  }
});
</script>

<template>
  <div class="client-card">
    <h5 class="client-card__title font-bold"
        v-if="title"
    >
      {{ title }}
    </h5>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.client-card {
  padding: 1rem;
  border: 1px solid #d1d1d1;
  border-radius: 8px;

  &__title {
    margin-bottom: 1rem;
    color: #000;
    font-size: 18px;
  }
}
</style>
