<script setup lang="ts">
import {ref} from 'vue';

import ModalContentSegment from "@/components/clients-update/ModalContentSegment.vue";

const isShowModal = ref<boolean>(false);

const handleShowModal = () => {
  isShowModal.value = true;
}

const handleCloseModal = () => {
  isShowModal.value = false;
}
</script>

<template>
  <div class="clients-update-alert">
    <div class="grid clients-update-alert__grid">
      <div class="clients-update-alert__left">
        <h6 class="clients-update-alert__title">
          Активируйте подписку на обновления
        </h6>
        <p class="clients-update-alert__text">
          Обновление позволит вам получать самые актуальные данные ваших клиентов. После активации подписки, данные клиентов будут обновляться автоматически. Настроить параметры обновления можно индивидуально для каждого пользователя или группы пользователей, сформировав сегмент, или для всех сразу.
        </p>
        <button class="ocm-btn clients-update-alert__btn"
                @click="handleShowModal"
        >
          Ознакомиться
        </button>
      </div>

      <div class="clients-update-alert__right">
        <img
            src="/images/clients-alert-img.svg"
            class="clients-update-alert__img"
            alt=""
        />
      </div>
    </div>

    <a-modal v-model:open="isShowModal"
             title="Выбор сегмента"
    >
      <template #footer>
        <div></div>
      </template>

      <ModalContentSegment
          :onCloseModal="handleCloseModal"
      />
    </a-modal>
  </div>
</template>

<style scoped lang="scss">
.clients-update-alert {
  width: 100%;
  max-width: 998px;
  padding: 1rem 32px;
  border-radius: 20px;
  background: var(--Gradient-05, linear-gradient(313deg, #BC77FF -3.89%, #A140FF 100%));

  &__grid {
    grid-template-columns: calc(100% - (28px + 254px)) 254px;
    gap: 28px;
  }

  &__title {
    margin-bottom: .7rem;
    padding-top: 1rem;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
  }

  &__text {
    margin-bottom: 24px;
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    font-weight: 500;
  }

  &__btn {
    height: 50px;
    border: 2px solid #fff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: transparent;

    &:hover, &:active {
      color: #A140FF;
      background: #fff;
    }
  }

  &__img {
    height: 254px;
    width: 254px;
    object-fit: cover;
  }
}
</style>
