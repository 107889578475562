<script setup lang="ts">
import {ITag} from "@/interfaces/Tags";

const {
  tag
} = defineProps({
  tag: {
    type: Object as () => ITag,
    required: true
  }
});
</script>

<template>
  <button class="list-tag">
    {{ tag.name }}
  </button>
</template>

<style scoped lang="scss">
.list-tag {
  padding: 4px 12px;
  color: #A140FF;
  border-radius: 120px;
  border: 1px solid #ECD9FF;
}
</style>
