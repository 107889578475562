<script setup lang="ts">
import {ref} from 'vue';
import { storeToRefs } from 'pinia'
import {useFilterStore} from "@/store/filters";

import OCMSelectCustom from "@/components/ocm-select/OCMSelectCustom.vue";

import type {IFilter} from "@/interfaces/Filters";
import type {IOCMSelect} from "@/interfaces/Form";
import type {IFilterSelect} from "@/interfaces/Filters";

const {filterItem} = defineProps({
  filterItem: {
    type: Array as () => Array<IFilter>,
    required: true
  }
});

const value = ref<Array<IFilter>>([]);
const filterStore = useFilterStore();
const { counterSelect } = storeToRefs(filterStore)

const optionsBase = [
  {
    value: 'Акнауцер',
    text: 'Акнауцер',
    isChecked: false
  },
  {
    value: 'Ригла',
    text: 'Ригла',
    isChecked: false
  },
  {
    value: 'МедСервис',
    text: 'МедСервис',
    isChecked: false
  },
  {
    value: 'Комплекс Мир',
    text: 'Комплекс Мир',
    isChecked: false
  }
];
const segments = [
  {
    value: 'Клиенты без номера телефона',
    text: 'Клиенты без номера телефона'
  },
  {
    value: 'Клиенты из Краснодара',
    text: 'Клиенты из Краснодара'
  },
  {
    value: 'Работают в Москве',
    text: 'Работают в Москве'
  },
  {
    value: 'Клиенты с аккредитацией до 30.12.2023',
    text: 'Клиенты с аккредитацией до 30.12.2023'
  },
  {
    value: 'Работают в поликлинике',
    text: 'Работают в поликлинике'
  }
];
const statues = [
  {
    value: null,
    text: 'Любой'
  },
  {
    value: 'Подтвержденный',
    text: 'Подтвержденный'
  },
  {
    value: 'Неподтвержденный',
    text: 'Неподтвержденный'
  }
];
const mailingTypes = [
  {
    value: null,
    text: 'Любой'
  },
  {
    value: 'mail',
    text: 'E-mail'
  },
  {
    value: 'Telegram',
    text: 'Telegram'
  },
  {
    value: 'WhatsApp',
    text: 'WhatsApp'
  }
];

const showAllFilters = () => {
  filterStore.showAsideMenu();
}

const handleSelectChange = (item:IFilterSelect|null, arr:Array<any>, key?:string) => {
  if(filterItem.type === 'select') {
    value.value = arr.slice();
    filterStore.updateCounterSelect(arr.length);
    filterStore.addItemActiveFilter(value.value);

  } else {
    if(item !== null) {
      if(item.value === null) value.value = [];
      else value.value = [item.text];
    }

    filterStore.addItemGeneralFilter({filterKey: key, text: item.text, isNull: item === null});
  }
}

const getItems = (type:string):Array<any> => {
  switch (type) {
    case 'base':
      return optionsBase;

    case 'segment':
      return segments;

    case 'status':
      return statues;

    case 'mailingType':
      return mailingTypes

    default:
      return [];
  }
}

const selectOptions:IOCMSelect = {
  name: filterItem.label,
  key: filterItem.key,
  isShowCheckbox: filterItem.type !== 'selectLine'
};

const clearAll = () => {
  value.value = [];
}

const onRemoveItem = (item:IFilter) => {
  let index = null;
  for(let i = 0; i <= value.value.length - 1; i++) {
    const valueItem = value.value[i];

    if(valueItem === item.text) {
      index = i;
    }
  }

  if(index !== null) {
    value.value.splice(index, 1);
  }
}

defineExpose({
  clearAll,
  onRemoveItem
})
</script>

<template>
  <div class="filter-item">
    <span class="filter-item__counter"
          v-if="filterItem.key !== 'all'"
          v-show="(filterItem.type === 'select' && counterSelect > 0) || (filterItem.type !== 'select' && value.length > 0)"
    >
      {{ filterItem.type === 'select' ? counterSelect : value.length }}
    </span>

    <OCMSelectCustom
        v-if="filterItem.type === 'select' || filterItem.type === 'selectLine'"
        :options="selectOptions"
        :items="getItems(filterItem.key)"
        :onChange="handleSelectChange"
    />

    <div v-if="filterItem.type === 'button'">
      <button class="ocm-btn filter-item__all"
              v-if="filterItem.key === 'all'"
              @click="showAllFilters"
      >
        <div class="filter-item__all__container flex items-center gap-2">
          <span class="filter-item__all__text">
            Все фильтры
          </span>
          <img
              class="filter-item__all__icon"
              src="/images/icon-filter-all.svg"
              alt=""
          />
        </div>
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.filter-item {
  position: relative;

  &__counter {
    position: absolute;
    top: -5px;
    right: -6px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--spacing-xs, 8px);
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    z-index: 10;
    font-weight: 700;
    background: var(--Pink-02, #EE307D);
  }

  & .ocm-select .ant-select-selector {
    color: #474EFF;
    border: 2px solid #8C90FF !important;
  }

  & .ant-select-selection-placeholder {
    color: #474EFF;
  }

  &__all {
    border: 2px solid #8C90FF;
    border-radius: 8px;
    background: #fff;

    &__text {
      position: relative;
      top: 1px;
      color: #474EFF;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.28px;
    }

    &:hover, &:active {
      border: 2px solid #474EFF;
      background: #fff;

      & .filter-item__all__text {
        color: #474EFF;
      }
    }
  }
}
</style>
