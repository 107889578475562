<script setup lang="ts">
const values = [
  {
    prop: 'Основной телефон',
    value: '+792312312'
  },
  {
    prop: 'Второй телефон',
    value: '+792312312'
  },
  {
    prop: 'Основаня почта',
    value: 'kl@mail.ru'
  },
  {
    prop: 'Вторая почта',
    value: 'kl@yandex.ru'
  },
  {
    prop: 'Telegram',
    value: '+792312312'
  },
  {
    prop: 'WhatsApp',
    value: '+792312312'
  }
]
</script>

<template>
  <div class="client-block-contacts">
    <ul class="client-block-contacts__list flex client-info-list">
      <li
          v-for="value in values"
          :key="value.prop"
          class="flex gap-2 justify-between"
      >
        <div class="client-info-prop">{{ value.prop }}</div>
        <div>
          {{ value.value }}
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

</style>
