<script setup lang="ts">
import {ref, onMounted} from "vue";

const {additionalElements} = defineProps({
  additionalElements: {
    type: Array as PropType<string>,
    required: false,
    default: []
  }
});

const isShowSlot = ref<boolean>(false);
const heightTable = ref<number>(0);
const isError = ref<boolean>(false);

const baseElems:string[] = [
  'AppHeader',
  'PaginationGeneral'
];

const setHeight = () => {
  try {
    isError.value = false;

    let sumH = 0;

    const elems = [...baseElems, ...additionalElements];

    for(let elem of elems) {
      const str = '#' + elem;
      const item = document.querySelector(str);

      if(item) {
        sumH += item.clientHeight
      }
    }

    const wHeight = document.documentElement.clientHeight;
    const tableH = Math.floor(wHeight - (sumH + 50));

    if(Number(tableH) && !Number.isNaN(tableH)) {
      heightTable.value = tableH;

    } else {
      isError.value = true;
    }

    isShowSlot.value = true;
    setTimeout(() => {
      const fixedTable = document.querySelector('.wrap_crm_table--fixed');

      if(fixedTable) {
        fixedTable.style.height = heightTable.value + 'px';
      }
    }, 1000);

  } catch(err) {
    console.error(err);
    isError.value = true;
  }
}

onMounted(() => {
  if(window) {
    setHeight();

    window.addEventListener("resize", setHeight,false);
  }
})
</script>

<template>
  <!-- :style="{height: heightTable + 'px'}" -->

  <div class="adaptive-height-table"
       :class="{'is-error': isError}"
  >
    <div class="adaptive-height-table__slot"
         v-if="isShowSlot"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.adaptive-height-table {
  &.is-error {
    height: auto !important;
  }
}
</style>
