<script setup lang="ts">
import {ref} from 'vue';

const isChecked = ref<boolean>(false);
</script>

<template>
  <div class="cell-auto-update">
    <a-switch v-model:checked="isChecked" />
  </div>
</template>

<style scoped lang="scss">

</style>
