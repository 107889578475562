<script setup lang="ts">
import CategoryItem from "@/components/categories/CategoryItem.vue";

const categories = [
  {
    title: '#1D1D1D',
    color: ''
  },
  {
    title: 'Валидирован',
    color: 'green'
  },
]
</script>

<template>
  <div class="client-block-main">
    <div class="client-block-main__categories flex gap-2">
      <CategoryItem
        v-for="category in categories"
        :key="category.title"
        :title="category.title"
        :color="category.color"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
