<template>
  <div class="templates-count">
    <span class="templates-count__value">50</span> найдено
  </div>
</template>

<style scoped lang="scss">
.templates-count {
  color: #C0C0CC;
  font-weight: 600;
  font-size: 15px;

  &__value {
    color: #AD17E5;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
