<script setup lang="ts">

</script>

<template>
  <div class="app-header-user">
    <div class="app-header-user__container grid">
      <div class="app-header-user__left">
        <img
          class="app-header-user__photo"
          src="/images/photo-user-example.svg"
          alt=""
        />
      </div>
      <div class="app-header-user__right">
        <h6 class="app-header-user__name">
          Константин Медрин
        </h6>
        <h6 class="app-header-user__role">
          Админиистратор
        </h6>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-header-user {
  &__container {
    grid-template-columns: 48px calc(100% - (48px + 10px));
    gap: 10px;
  }

  &__photo {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    border: 2px solid #666BFF;
  }

  &__name,
  &__role {
    font-weight: 600;
  }


  &__name {
    margin-bottom: 2px;
    color: #1D1D1F;
    font-size: 16px;
  }

  &__role {
    color: #A8A8B2;
    font-size: 14px;
  }
}
</style>
