<script setup lang="ts">
import { RightOutlined } from '@ant-design/icons-vue';

const {currentPage, onChangePage, countPages} = defineProps({
  currentPage: {
    type: Number,
    required: true
  },
  onChangePage: {
    type: Function,
    required: false,
    default: null
  },
  countPages: {
    type: Number,
    required: true,
    default: 0
  }
});

const handleAction = (action:string) => {
  console.log('onChangePage', onChangePage);

  if(action === 'prev') {
    if(currentPage - 1 > 1) {
      if(onChangePage) {
        onChangePage(currentPage - 1);
      }
    }
  }

  if(action === 'next') {
    if(currentPage + 1 > countPages) {
      if(onChangePage) {
        onChangePage(currentPage + 1);
      }
    }
  }
}

const handleChangePage = (page:number|string) => {
  if(onChangePage) {
    onChangePage(page);
  }
}
</script>

<template>
  <div class="pagination-default flex gap-1">
<!--    <button class="pagination-default-btn"-->
<!--            :disabled="currentPage > 1"-->
<!--            @click="handleAction('prev')"-->
<!--    >-->
<!--      prev-->
<!--    </button>-->
    <button class="pagination-default-btn active"
            @click="handleChangePage(1)"
    >
      1
    </button>
    <button class="pagination-default-btn"
            @click="handleChangePage(2)"
    >
      2
    </button>
    <button class="pagination-default-btn"
            @click="handleChangePage(3)"
    >
      3
    </button>

    <button class="pagination-default-btn">
      <span class="pagination-default-btn__pointer">...</span>
    </button>

    <button class="pagination-default-btn"
            @click="handleChangePage(countPages)"
    >
      {{ countPages }}
    </button>
    <button class="pagination-default-btn flex items-center justify-center"
            @click="handleAction('next')"
            :disabled="currentPage + 1 > countPages"
    >
      <RightOutlined />
    </button>
  </div>
</template>

<style scoped lang="scss">
.pagination-default {
  &-btn {
    height: 36px;
    width: 36px;
    border-radius: 8px;
    border: 1px solid #F2F3FF;
    color: #353538;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background: #fff;

    &:hover, &:active, &.active {
      color: #fff;
      background: #474EFF;
    }

    &__pointer {
      height: 20px;
      position: relative;
      top: -4px;
    }
  }
}
</style>
