<script setup lang="ts">
import {ref, onMounted} from 'vue';

import BlockFilters from "@/components/filters/BlockFilters.vue";
import TableAdaptiveHeight from "@/components/tables/TableAdaptiveHeight.vue";
import NavigationTabs from "@/components/navigation/NavigationTabs.vue";
import CRMLoader from "@/components/loader/CRMLoader.vue";
import OCMTableDefault from "@/components/tables/OCMTableDefault.vue";
import PaginationDefault from "@/components/pagination/PaginationDefault.vue";
import CellName from '@/components/segments/CellName.vue';
import CellAutoUpdate from "@/components/segments/CellAutoUpdate.vue";
import CellControl from "@/components/segments/CellControl.vue";

import {INavTab} from "@/interfaces/Navigation";
import {IThead} from "@/interfaces/Table";

const tabs: INavTab[] = [
  {
    key: 'clients',
    label: 'Список сегментов'
  },
  {
    key: 'archive',
    label: 'Архив'
  },
];
const items = [
  {
    id: 1,
    name: 'Педиатр, которые были на курсе по педиатрии',
    count: 150,
    date_create: '27.10.2023',
    date_update: '27.10.2023',
    mailing: 12,
    isUpdate: true,
    isUpdated: true
  },
  {
    id: 2,
    name: 'Были на мероприятиях',
    count: 150,
    date_create: '27.10.2023',
    date_update: '27.10.2023',
    mailing: 12,
    isUpdate: true,
    isUpdated: false
  },
  {
    id: 3,
    name: 'Мужчины акушеры из Москвы',
    count: 150,
    date_create: '27.10.2023',
    date_update: '27.10.2023',
    mailing: 12,
    isUpdate: true,
    isUpdated: true
  },
  {
    id: 4,
    name: 'Были на лекции Реабилитация пациентов с болезнью Паркинсона',
    count: 150,
    date_create: '27.10.2023',
    date_update: '27.10.2023',
    mailing: 12,
    isUpdate: true,
    isUpdated: false
  },
  {
    id: 5,
    name: 'Хирурги из Ростова',
    count: 150,
    date_create: '27.10.2023',
    date_update: '27.10.2023',
    mailing: 12,
    isUpdate: true,
    isUpdated: true
  },
  {
    id: 6,
    name: 'Окончание аккредитации 20.12.2023',
    count: 150,
    date_create: '27.10.2023',
    date_update: '27.10.2023',
    mailing: 12,
    isUpdate: true,
    isUpdated: false
  }
];
const listTHead: IThead[] = [
  {
    key: 'name',
    label: 'Название',
    content: CellName
  },
  {
    key: 'count',
    label: 'Количество клиентов'
  },
  {
    key: 'date_create',
    label: 'Дата создания'
  },
  {
    key: 'date_update',
    label: 'Дата обновления'
  },
  {
    key: 'mailing',
    label: 'Рассылок'
  },
  {
    key: 'autoUpdate',
    label: 'Автомат. обновление',
    content: CellAutoUpdate
  },
  {
    key: 'control',
    label: '',
    content: CellControl,
    thClass: 'th-control'
  }
];

const isLoading = ref<boolean>(true);
const view = ref<string>('');

const getItems = () => {
  isLoading.value = true;

  setTimeout(() => {
    isLoading.value = false;
  }, 600);
}

const handleChangeTab = (tab: INavTab) => {
  view.value = tab.key;
  getItems();
}

const handleChangeCurrentPage = () => {
  getItems();
}

onMounted(() => {
  getItems();
})
</script>

<template>
  <div class="segments-view">
    <div class="segments-view__tabs">
      <NavigationTabs
          :onChangeTab="handleChangeTab"
          :tabs="tabs"
      />
    </div>

    <div class="segments-view__filters">
      <BlockFilters/>
    </div>

    <div class="segments-view__items">
      <CRMLoader v-if="isLoading"/>
      <div v-else>
        <TableAdaptiveHeight>
          <OCMTableDefault
              :listTHead="listTHead"
              :data="items"
          />
        </TableAdaptiveHeight>

        <div class="segments-view__pagination">
          <PaginationDefault
              :currentPage="1"
              :onChangePage="handleChangeCurrentPage"
              :countPages="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.segments-view {

}
</style>
