<script setup lang="ts">
import {ref, reactive} from 'vue';

const optionsPhone:string[] = [
  'Не важно',
  'Есть',
  'Нет',
];
const optionsMail:string[] = [
  'Не важно',
  'Есть',
  'Нет',
];

const radioStyle = reactive({
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
});

const valuePhone = ref<string>('');
const valueMail = ref<string>('');
</script>

<template>
  <div class="filter-contacts flex gap-5">
    <div class="form-group">
      <label class="filter-aside__label mb-3">
        Телефон
      </label>
      <a-radio-group v-model:value="valuePhone">
        <a-radio
            v-for="option in optionsPhone"
            :key="option"
            :style="radioStyle" :value="option">{{ option }}</a-radio>
      </a-radio-group>
    </div>

    <div class="form-group">
      <label class="filter-aside__label mb-3">
        Почта
      </label>
      <a-radio-group v-model:value="valueMail">
        <a-radio
            v-for="option in optionsMail"
            :key="option"
            :style="radioStyle" :value="option"
        >{{ option }}</a-radio>
      </a-radio-group>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filter-contacts {
  flex-direction: column;
}
</style>
