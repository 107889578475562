<script setup lang="ts">
import {ref} from 'vue';

import {ISelectOptionSimple} from "@/interfaces/Form";

const {onCloseModal} = defineProps({
  onCloseModal: {
    type: Function,
    required: true
  }
});

const activeSegment = ref<string>('');
const options:ISelectOptionSimple[] = [
  {
    value: 'all',
    label: 'Все пользователи'
  },
  {
    value: 'doctors',
    label: 'Врачи из Краснодара'
  },
  {
    value: 'obstetricians',
    label: 'Акушеры из Москвы'
  },
  {
    value: 'surgeons',
    label: 'Хирурги из Самары'
  }
];
</script>

<template>
  <div class="modal-content-segment">
    <div class="modal-content-segment__content">
      <a-select class="ocm-select"
                :options="options"
                placeholder="Сегмент"
                v-model:value="activeSegment"
      />
    </div>

    <div class="flex items-center gap-3">
      <button class="ocm-btn"
              @click="onCloseModal"
              :disabled="!activeSegment"
      >
        Применить
      </button>
      <button class="ocm-btn"
              @click="onCloseModal"
      >
        Отмена
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-content-segment {
  &__content {
    margin-bottom: 110px;
  }
}
</style>
