<script setup lang="ts">
import {reactive, ref} from "vue";

const optionsTypeSpeciality:string[] = [
  'Любой',
  'Медицинская',
  'Не медицинская',
];
const optionsTypeReception:string[] = [
    'Стационарный',
    'Амбулаторный',
    'Любой'
];

const radioStyle = reactive({
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
});

const valueSpeciality = ref<string>('');
const valueReception = ref<string>('');
</script>

<template>
  <div class="filter-specialization flex">
    <div class="form-group">
      <label class="filter-aside__label">
        Тип специальности
      </label>
      <a-radio-group v-model:value="valueSpeciality">
        <a-radio
            v-for="option in optionsTypeSpeciality"
            :key="option"
            :style="radioStyle" :value="option">{{ option }}</a-radio>
      </a-radio-group>
    </div>

    <div class="form-group">
      <a-select class="filter-aside-select ocm-select"
                placeholder="Специальность"
      >
      </a-select>
    </div>

    <div class="form-group">
      <label class="filter-aside__label">
        Тип приёма
      </label>
      <a-radio-group v-model:value="valueReception">
        <a-radio
            v-for="option in optionsTypeReception"
            :key="option"
            :style="radioStyle" :value="option">{{ option }}</a-radio>
      </a-radio-group>
    </div>

    <div class="form-group">
      <a-select class="filter-aside-select ocm-select"
                placeholder="Специальность"
      >
      </a-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filter-specialization {
  flex-direction: column;
  gap: 20px;

  & .filter-aside__label {
    margin-bottom: 12px;
  }
}
</style>
