<script setup lang="ts">
const values = [
  {
    prop: 'Дата регистрации',
    companyName: 'Акнауцер',
    date: '14.03.2023',
    key: 1
  },
  {
    prop: 'Дата регистрации',
    companyName: 'Ригла',
    date: '20.03.2023',
    key: 2
  },
  {
    prop: 'Последняя активность',
    companyName: null,
    date: 'Сегодня в 20:30',
    key: 3
  }
];
</script>

<template>
  <div class="client-block-dates">
    <ul class="client-block-dates__list flex">
      <li
          v-for="item in values"
          :key="item.key"
          class="flex gap-2 justify-between"
      >
        <div>
          <span class="client-block-dates__prop client-info-prop">
            {{ item.prop }}
          </span>
          <span class="client-block-dates__company">
            {{ item.companyName }}
          </span>
        </div>
        <div class="client-block-dates__date">
          {{ item.date }}
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.client-block-dates {
  &__list {
    flex-direction: column;
    gap: .5rem;
  }

  &__prop {
    padding-right: .2rem;
  }

  &__date,
  &__company {
    color: #000;
  }

  &__company {
    font-style: italic;
  }
}
</style>
