import { defineStore } from 'pinia'

export const useTableStore = defineStore('table', {
    state: () => {
        return {
            isSelectAllItems: false
        }
    },

    actions: {
        toggleSelectAll() {
            console.log('toggle');
            this.isSelectAllItems = !this.isSelectAllItems;
        }
    }
})
