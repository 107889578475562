<script setup lang="ts">
import TableAdaptiveHeight from "@/components/tables/TableAdaptiveHeight.vue";
import PaginationDefault from "@/components/pagination/PaginationDefault.vue";
import OCMTableDefault from "@/components/tables/OCMTableDefault.vue";
import CellLink from "@/components/tables/CellLink.vue";
import CellDetails from "@/components/tables/CellDetails.vue";

import {IThead} from "@/interfaces/Table";

const listTHead:IThead[] = [
  {
    key: 'url',
    label: 'Ссылка',
    content: CellLink
  },
  {
    key: 'clicks',
    label: 'Клики'
  },
  {
    key: 'uniqClicks',
    label: 'Уник. клики'
  },
  {
    key: 'control',
    label:  '',
    content: CellDetails,
    thClass: 'th-control'
  }
];
const clicks = [
  {
    id: 1,
    url: 'https://aknaucer.ru/schedule/74803/',
    clicks: 245,
    uniqClicks: 205
  },
  {
    id: 2,
    url: 'https://aknaucer.ru/schedule/74803/',
    clicks: 333,
    uniqClicks: 111
  },
];
</script>

<template>
  <div class="company-clicks">
    <TableAdaptiveHeight>
      <OCMTableDefault
          :listTHead="listTHead"
          :data="clicks"
      />
    </TableAdaptiveHeight>

    <PaginationDefault
        :currentPage="1"
        :countPages="3"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
