<script setup lang="ts">
import {ref} from 'vue';

import SegmentParam from "@/components/clients/SegmentParam.vue";

const {closeModal} = defineProps({
  closeModal: {
    type: Function,
    required: true
  }
})

const params1:string[] = [
  'Фамилия',
  'Имя',
  'Отчество',
  'Пол',
  'Дата рождения',
  'Основной телефон',
  'Второй телефон',
];
const params2:string[] = [
  'Основная почта',
  'Вторая почта',
  'Telegram',
  'WhatsApp',
  'Viber',
  'Vk',
  'Одноклассники',
];
const params3:string[] = [
  'Регион',
  'Город',
  'Специальность',
  'Аккредитация',
  'Место работы'
];
const newNameSegment = ref<string>('');
</script>

<template>
  <div class="modal-create-segment">
    <a-input class="modal-create-segment__search"
             placeholder="Название сегмента"
             v-model:value="newNameSegment"
    />

    <div class="modal-create-segment__params">
      <h5 class="modal-create-segment__params__title">
        Выберите обновляемые параметры
      </h5>

      <div class="modal-create-segment__params__blocks">
        <div class="modal-create-segment__block">
          <ul>
            <li
                v-for="param in params1"
                :key="param"
            >
              <SegmentParam
                  :paramName="param"
              />
            </li>
          </ul>
        </div>
        <div class="modal-create-segment__block">
          <ul>
            <li
                v-for="param in params2"
                :key="param"
            >
              <SegmentParam
                  :paramName="param"
              />
            </li>
          </ul>
        </div>
        <div class="modal-create-segment__block">
          <ul>
            <li
                v-for="param in params3"
                :key="param"
            >
              <SegmentParam
                  :paramName="param"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="modal-create-segment__footer flex items-center">
      <button class="ocm-btn"
              @click="closeModal"
              :disabled="!newNameSegment"
      >
        Применить
      </button>
      <button class="ocm-btn ocm-btn--link"
              @click="closeModal"
      >
        Отмена
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-create-segment {
  &__search {
    margin-bottom: 20px;
    width: 100%;
    color: #A8A8B2;
    height: 56px;
  }

  &__params {
    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: 500;
    }

    &__blocks {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 1rem;
    }
  }

  &__block {
    & ul {
      display: flex;
      gap: 12px;
      flex-direction: column;
    }
  }

  &__footer {
    margin-top: 32px;
    gap: 12px;
  }
}
</style>
