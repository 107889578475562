<script setup lang="ts">
import {ref} from 'vue';
import { notification } from 'ant-design-vue';

import {ISelectOptionSimple} from "@/interfaces/Form";

const {onHideModal} = defineProps({
  onHideModal: {
    type: Function,
    required: false
  }
});

const regions:ISelectOptionSimple[] = [
  {
    value: 'moscow',
    label: 'Moscow'
  },
  {
    value: 'spb',
    label: 'SPB'
  },
  {
    value: 'kazan',
    label: 'Kazan'
  }
];
const cities:ISelectOptionSimple[] = [
  {
    value: 'moscow',
    label: 'Moscow'
  },
  {
    value: 'spb',
    label: 'Kazan'
  },
  {
    value: 'kazan',
    label: 'spb'
  }
];

const region = ref<string|null>(null);
const city =  ref<string|null>(null);

const closeModal = () => {
  console.log('onHideModal', onHideModal);

  if(onHideModal) {
    onHideModal();
  }
}

const handleCloseModal = () => {
  closeModal();
}

const handleAccept = () => {
  notification.success({
    message: 'Редактирование прошло успешно'
  });

  closeModal();
}
</script>

<template>
  <div class="modal-mass-edit">
    <h3 class="modal-mass-edit__title">
      Массовое редактирование
    </h3>

    <div class="modal-mass-edit__content flex gap-5">
      <div class="form-group">
        <a-select class="ocm-select"
                  :options="regions"
                  placeholder="Регион"
                  v-model:value="region"
        >
        </a-select>
      </div>
      <div class="form-group">
        <a-select class="ocm-select"
                  :options="cities"
                  placeholder="Город"
                  v-model:value="city"
        />
      </div>
    </div>

    <div class="modal-mass-edit__footer flex gap-2">
      <button class="ocm-btn"
              :disabled="!city || !region"
              @click="handleAccept"
      >
        Применить
      </button>
      <button class="ocm-btn ocm-btn--white"
              @click="handleCloseModal"
      >
        Отмена
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-mass-edit {
  &__title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
  }

  &__content {
    flex-direction: column;
  }

  &__footer {
    margin-top: 110px;
  }
}
</style>
