<script setup lang="ts">

</script>

<template>
  <div class="company-table-cell-name">
    <router-link to="/companies/1" class="company-table-cell-name__link">
      Ригла
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.company-table-cell-name {
  &__link {
    color: #1a20ff;
  }
}
</style>
