<script setup lang="ts">
import {ref} from 'vue';

import TabsButtons from "@/components/tabs/TabsButtons.vue";
import ActionInPortal from "@/components/client-right-aside/actions/ActionInPortal.vue";
import ActionMailing from "@/components/client-right-aside/actions/ActionMailing.vue";

import type {ITableAction} from '@/interfaces/Tabs';

const view = ref<string>('portal');
const tabs:ITableAction[] = [
  {key: 'portal', name: 'Действия на портале'},
  {key: 'mailing', name: 'Рассылки'},
];

const handleChangeView = (key:string) => {
  view.value = key;
}
</script>

<template>
  <div class="block-actions">
    <div class="block-actions__tabs">
      <TabsButtons
          :change="handleChangeView"
          :tabs="tabs"
      />
    </div>

    <div class="block-actions__content">
      <ActionInPortal v-if="view === 'portal'" />
      <ActionMailing v-if="view === 'mailing'" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.block-actions {
  &__tabs {
    margin-bottom: 24px;
  }
}
</style>
