<script setup lang="ts">
import {IFilterAsideAction} from "@/interfaces/Filters";

const actions:IFilterAsideAction[] = [
  {
    name: 'Посетил ресурс',
    isChecked: false
  },
  {
    name: 'Зарегистрировался',
    isChecked: false
  },
  {
    name: 'Явился на мероприятие',
    isChecked: false
  },
  {
    name: 'Успешно прошёл мероприятие',
    isChecked: false
  }
];
</script>

<template>
  <div class="filter-aside-events">
    <a-select
        class="ocm-select filter-aside-select"
        placeholder="Мероприятие"
    ></a-select>

    <label class="filter-aside__label filter-aside-events__action-title">
      Действия
    </label>

    <div class="filter-aside-events__actions flex gap-4">
      <div class="form-group"
           v-for="action in actions"
           :key="action"
      >
        <a-checkbox
            v-model="action.isChecked"
        >
          {{ action.name }}
        </a-checkbox>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filter-aside-events {
  &__actions {
    flex-direction: column;
  }

  &__action-title {
    margin: 20px 0 12px;
  }
}
</style>
