<script setup lang="ts">
import {ref} from "vue";

import {IThead} from "@/interfaces/Table";
import {IFilter} from "@/interfaces/Filters";
import PaginationDefault from "@/components/pagination/PaginationDefault.vue";
import BlockFilters from "@/components/filters/BlockFilters.vue";
import TableAdaptiveHeight from "@/components/tables/TableAdaptiveHeight.vue";
import CRMLoader from "@/components/loader/CRMLoader.vue";
import OCMTableDefault from "@/components/tables/OCMTableDefault.vue";

const isLoading = ref<boolean>(false);
const listTHead: IThead[] = [
  {
    key: 'type',
    label: 'Тип рассылки'
  },
  {
    key: 'name',
    label: 'Название кампании'
  },
  {
    key: 'partner',
    label: 'Партнёр'
  },
  {
    key: 'date',
    label: 'Дата отправки'
  },
  {
    key: 'status',
    label: 'Статус'
  }
];
const items = [
  {
    id: 1,
    type: 'E-mail',
    name: 'Полезная информация для терапевтов',
    partner: 'ООО «Медитекс центр»',
    date: '24.11.2023',
    status: 'Запланировано'
  },
  {
    id: 2,
    type: 'SMS',
    name: 'Приглашение на курс «Функциональные методы диагн...',
    partner: 'ООО «Ригла»',
    date: '24.11.2023',
    status: 'Черновик'
  },
  {
    id: 3,
    type: 'Telegram',
    name: 'Приглашение на курсы',
    partner: 'ООО «Ригла»',
    date: '24.11.2023',
    status: 'Завершена'
  },
  {
    id: 4,
    type: 'E-mail',
    name: 'Полезная информация для терапевтов',
    partner: 'ООО «Ригла»',
    date: '22.12.2023',
    status: 'Запланировано'
  }
]
const filters:IFilter[] = [
  {
    key: 'base',
    label: 'База партнёра',
    type: 'select'
  },
  {
    key: 'mailingType',
    label: 'Тип рассылки',
    type: 'select'
  }
];

const handleChangeCurrentPage = () => {
  console.log('handleChangeCurrentPage');
}
</script>

<template>
  <div class="companies-archive">
    <div class="companies__filters">
      <BlockFilters
          :listFilters="filters"
          :is-show-control="true"
      >
        <template #control>
          <button class="ocm-btn">
            Создать кампанию
          </button>
        </template>
      </BlockFilters>
    </div>

    <CRMLoader v-if="isLoading"/>
    <div v-else>
      <TableAdaptiveHeight>
        <OCMTableDefault
            :listTHead="listTHead"
            :data="items"
        />
      </TableAdaptiveHeight>

      <div class="companies__pagination">
        <PaginationDefault
            :currentPage="1"
            :onChangePage="handleChangeCurrentPage"
            :countPages="3"
        />
      </div>
    </div>
  </div>
</template>
