<script setup lang="ts">

</script>

<template>
  <router-link
      class="app-logo"
      to="/clients"
  >
    <img src="/images/logo.svg"
         alt="Акнауцер"
         class="app-logo__img"
    />
  </router-link>
</template>

<style scoped lang="scss">
.app-logo {
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
