<script setup lang="ts">
import {ref} from 'vue';
import {useFilterStore} from "@/store/filters";
import { onClickOutside } from '@vueuse/core';

import type {IOCMSelect} from "@/interfaces/Form";
import type {IFilterSelect} from "@/interfaces/Filters";

const {
  options,
  items,
  onChange
} = defineProps({
  items: {
    type: Array as () => Array<IFilterSelect>,
    required: false,
    default: []
  },
  options: {
    type: Object as () => IOCMSelect,
    required: true
  },
  onChange: {
    type: Function,
    required: false
  }
})

const isShowItems = ref<boolean>(false);
const countSelected = ref<number>(0);
const optionsItems = ref(items || []);
const targetSelectCustom = ref(null);

const filterStore = useFilterStore();

const toggleView = () => {
  console.log('toggle', isShowItems.value);
  isShowItems.value = !isShowItems.value;
}

const handleSelect = (item:IFilterSelect, type:string = 'line') => {
  if(type === 'line') {
    if(onChange) onChange(item, [], options.key);

    isShowItems.value = false;

  } else {
    const arr = [];
    countSelected.value = 0;
    for(let optionItem of optionsItems.value) {
      if(optionItem.isChecked) {
        countSelected.value++;
        arr.push(optionItem.text);
      }
    }
    if(onChange) onChange(null, arr);
  }
}

filterStore.$subscribe((mutation, state) => {
  if(options.isShowCheckbox && countSelected.value > state.activeFilters.length) {
    for(let item of items) {
      item.isChecked = false;
    }

    const arr = [];
    for(let item of optionsItems.value) {
      for(let activeItem of state.activeFilters) {
        if(activeItem === item.text) {
          item.isChecked = true;
          arr.push(item.text);
        }
      }
    }

    filterStore.updateCounterSelect(arr.length);
  }
})

onClickOutside(targetSelectCustom, (event) => {
  const classes = [
      'ocm-select-custom__header',
      'ocm-select-custom__header__item',
      'ocm-select-custom__header'
  ];

  if(!classes.includes(event.target.className)) {
    isShowItems.value = false;
  }
})
</script>

<template>
  <div class="ocm-select-custom">
    <button
        class="ocm-select-custom__header"
        @click="toggleView"
    >
      <div class="ocm-select-custom__header__container">
        <span class="ocm-select-custom__header__item">{{ options.name }}</span>
        <img src="/images/icon-filter-arrow.svg"
             alt=""
             class="ocm-select-custom__header__item"
        />
      </div>
    </button>

    <ul class="ocm-select-custom__list"
        v-if="isShowItems"
        ref="targetSelectCustom"
    >
      <li
          v-for="item in optionsItems"
          :key="item.key"
          :class="{'checkbox': options.isShowCheckbox}"
      >
        <div class="ocm-select-custom__list__item">
          <template v-if="options.isShowCheckbox">
            <a-checkbox
                v-model:checked="item.isChecked"
                @change="handleSelect(item, 'checkbox')"
            >
              {{ item.text }}
            </a-checkbox>
          </template>

          <a class="ocm-select-custom__list__text"
             @click="handleSelect(item)"
             v-if="!options.isShowCheckbox"
          >
            {{ item.text }}
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.ocm-select-custom {
  position: relative;
  width: 100%;

  &__header {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 2px solid var(--Blue-04, #8C90FF);
    padding: 0 24px;
    color: #474EFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.28px;
    display: flex;
    align-items: center;
    background: #fff;

    &__container {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__list {
    position: absolute;
    top: 105%;
    left: 0;
    width: 100%;
    max-width: 300px;
    height: auto;
    max-height: 300px;
    z-index: 10;
    box-shadow: 0 4px 20px 0 rgba(66, 58, 166, 0.08);
    overflow-y: auto;
    background: #fff;

    & li {
      border-bottom: 1px solid #EDEDFA;
      cursor: pointer;

      &.checkbox label {
        width: 100%;
        padding: 8px 12px;
      }

      &:hover {
        background: rgba(238,238,238, .3);
      }

      &:not(.checkbox) {
        & a {
          width: 100%;
          padding: 8px 12px;
          display: inline-block;
        }
      }
    }

    &__text {
      color: #1D1D1F;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
</style>
