<script setup lang="ts">
import {ref} from 'vue';

import { CloseCircleOutlined } from '@ant-design/icons-vue';

import FilterItem from "@/components/filters/FilterItem.vue";

import {IFilter} from "@/interfaces/Filters";
import {useFilterStore} from "@/store/filters";
import {storeToRefs} from "pinia";

const {listFilters, isShowControl} = defineProps({
  listFilters: {
    type: Array as () => Array<IFilter>,
    required: false,
    default: []
  },
  isShowControl: {
    type: Boolean,
    required: false,
    default: false
  }
});


const filterStore = useFilterStore();
const { activeFilters, generalFilter } = storeToRefs(filterStore)
const RefFilterItem = ref(null);

const removeActiveFilter = (index:number) => {
  filterStore.removeItemActiveFilter(index);
}

const removeGeneralFilter = (index:number, activeFilter:object) => {
  filterStore.removeGeneralFilter(index);

  console.log('activeFilter', activeFilter);
  for(let item of RefFilterItem.value) {
    item.onRemoveItem(activeFilter);
  }
}

const clearAll = () => {
  filterStore.clearAll();

  for(let item of RefFilterItem.value) {
    item.clearAll();
  }
}
</script>

<template>
  <div class="block-filters">
    <div class="block-filters__top flex gap-4 justify-between">
      <div class="block-filters__items flex gap-2 flex-wrap">
        <FilterItem
            v-for="filterItem in listFilters"
            :key="filterItem.key"
            :filterItem="filterItem"
            :activeFilters="activeFilters"
            ref="RefFilterItem"
        />
        <button class="ocm-btn ocm-btn--link block-filter__clear-all"
                @click="clearAll"
                v-if="listFilters.length > 0"
        >
          Сбросить все
        </button>
      </div>

      <div class="block-filters__control flex justify-end"
           v-if="isShowControl"
      >
        <slot name="control"></slot>
      </div>
    </div>

    <ul class="block-filters__bottom flex gap-2 flex-wrap"
        v-if="activeFilters.length > 0 || generalFilter.length > 0"
    >
      <li
        v-for="(activeFilter, index) in activeFilters"
        :key="activeFilter"
        class="block_filters__active-item flex items-center gap-2"
      >
        <span class="block_filters__active-item__text">
          {{ activeFilter }}
        </span>
        <button
            class="block_filters__active-item__remove ocm-btn ocm-btn--icon"
            @click="removeActiveFilter(index)"
        >
          <CloseCircleOutlined />
        </button>
      </li>

      <li
          v-for="(activeFilter, index) in generalFilter"
          :key="activeFilter"
          class="block_filters__active-item flex items-center gap-2"
      >
        <span class="block_filters__active-item__text">
          {{ activeFilter.text }}
        </span>
        <button
            class="block_filters__active-item__remove ocm-btn ocm-btn--icon"
            @click="removeGeneralFilter(index, activeFilter)"
        >
          <CloseCircleOutlined />
        </button>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.block-filters {
  margin-top: 40px;
}

.block-filters__bottom {
  padding: 12px 0;
}

.block_filters__active-item {
  padding: 6px 12px;
  border-radius: 60px;
  color: #A140FF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.42px;
  background: #F9F2FF;

  &__remove {
    position: relative;
    top: -2px;
    color: #ECD9FF;
  }

  &:hover, &:active {
    color: #fff;
    background: #474EFF;

    & .block_filters__active-item__text,
    & .block_filters__active-item__remove {
      color: #fff;
    }
  }
}

.block-filter__clear-all {
  margin-left: 1rem;
  color: #474EFF;
}
</style>
