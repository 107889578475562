<script setup lang="ts">
import { ref } from 'vue'

import type { ITab } from '@/interfaces/Tabs'

const props = defineProps({
  options: {
    type: Array as () => Array<ITab>,
    required: true,
  },
  onSelect: {
    type: Function,
    required: true,
  },
})

const active = ref<string>(props.options[0].key || '')

function selectOption(option: ITab) {
  if (props.onSelect)
    props.onSelect(option)

  active.value = option.key
}
</script>

<template>
  <nav class="crm_tabs flex items-start mb-8">
    <a
      v-for="option in props.options"
      :key="option.key"
      class="crm_tabs__item"
      :class="{ active: option.key === active }"
      @click="selectOption(option)"
    >
      {{ option.name }}
    </a>
  </nav>
</template>

<style scoped lang="scss">
.crm_tabs {
  gap: 32px;

  &__item {
    padding-bottom: 11px;
    border-bottom: 2px solid transparent;
    color: #777989;
    font-weight: 600;
    cursor: pointer;

    &.active {
      color: #4782FF;
      border-color: #4782FF;
    }
  }
}
</style>
