<script setup lang="ts">
import {ref} from 'vue';

const isDisabled = ref<boolean>(false);
</script>

<template>
  <div class="cell-control">
    <button
        class="ocm-btn ocm-btn--link"
        :disabled="isDisabled"
    >
      Обновить
    </button>
  </div>
</template>

<style scoped lang="scss">

</style>
