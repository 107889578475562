<script setup lang="ts">
import TemplatesCount from "@/components/companies-templates/TemplatesCount.vue";
import PaginationDefault from "@/components/pagination/PaginationDefault.vue";

import {ITemplate} from "@/interfaces/Templates";
import TemplateCard from "@/components/companies-templates/TemplateCard.vue";

const templates:ITemplate[] = [
  {
    id: 1,
    tags: [
      {name: 'Pop-up', key: '1'},
      {name: 'Pop-up', key: '2'},
      {name: 'Pop-up', key: '3'},
    ],
    type: 'event',
    title: 'Приглашение на вебинар в Краснодаре',
    date: '14.10.2023'
  },
  {
    id: 2,
    tags: [
      {name: 'Pop-up', key: 2},
      {name: 'Pop-up', key: 4},
      {name: 'Pop-up', key: 5},
    ],
    type: 'message',
    title: 'Приглашение на вебинар',
    date: '14.10.2023'
  },
  {
    id: 3,
    tags: [
      {name: 'Pop-up', key: 6},
      {name: 'Pop-up', key: 7}
    ],
    type: 'event',
    title: 'Приглашение на вебинар',
    date: '14.10.2023'
  },
  {
    id: 4,
    tags: [
      {name: 'Pop-up', key: 8},
    ],
    type: 'event',
    title: 'Приглашение на вебинар',
    date: '14.10.2023'
  },
  {
    id: 5,
    tags: [
      {name: 'Pop-up', key: 11},
      {name: 'Pop-up', key: 12},
      {name: 'Pop-up', key: 13},
    ],
    type: 'event',
    title: 'Приглашение на вебинар',
    date: '14.10.2023'
  }
];

const handleChangeCurrentPage = () => {
  console.log('handleChangePagination');
}
</script>

<template>
  <div class="companies-templates">
    <div class="companies-templates__header mb-3">
      <TemplatesCount />
    </div>

    <div class="companies-templates__items flex flex-wrap gap-4"
    >
      <div
        v-for="template in templates"
        :key="template.key"
      >
        <TemplateCard
            :template="template"
        />
      </div>
    </div>

    <div class="companies-templates__pagination">
      <PaginationDefault
          :currentPage="1"
          :onChangePage="handleChangeCurrentPage"
          :countPages="3"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.companies-templates {
  &__header {
    margin-top: 40px;
  }
}
</style>
