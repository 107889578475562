import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import ClientsView from "@/views/ClientsView.vue";
import ClientView from "@/views/ClientView.vue";
import SegmentsView from "@/views/SegmentsView.vue";
import CompaniesView from "@/views/CompaniesView.vue";
import CompanyView from "@/views/CompanyView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: ClientsView
  },
  {
    path: '/clients',
    name: 'clients',
    component: ClientsView
  },
  {
    path: '/clients/:id',
    name: 'client',
    component: ClientView
  },
  {
    path: '/segments',
    name: 'segments',
    component: SegmentsView
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompaniesView
  },
  {
    path: '/companies/:id',
    name: 'company',
    component: CompanyView
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
