<script setup lang="ts">
import ListTag from '@/components/tags/ListTag.vue'

import type {ITag} from "@/interfaces/Tags";

const {
  tags
} = defineProps({
  tags: {
    type: Array as () => Array<ITag>,
    required: false,
    default: []
  }
})
</script>

<template>
  <div class="list-tags flex items-center gap-1">
    <div v-for="tag in tags"
         :key="tag.key"
    >
      <ListTag
          :tag="tag"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
