import { defineStore } from 'pinia'

export const useFilterStore = defineStore('filters', {
    state: () => {
        return {
            isShowAside: false,
            activeFilters: [],
            counterSelect: 0,
            generalFilter: []
        }
    },

    actions: {
        clearAll() {
          this.activeFilters = [];
          this.counterSelect = 0;
          this.generalFilter = [];
        },

        showAsideMenu() {
            this.isShowAside = true;
        },

        closeAsideMenu() {
            this.isShowAside = false;
        },

        removeItemActiveFilter(index:number) {
            this.activeFilters.splice(index, 1);
        },

        addItemActiveFilter(items:any) {
            this.activeFilters = items.slice();
        },

        updateCounterSelect(value:number) {
            this.counterSelect = value;
        },

        addItemGeneralFilter(item:any) {
            let isHas = false;
            for(let generalItem of this.generalFilter) {
                // @ts-ignore
              if(generalItem.filterKey === item.filterKey) {
                  // @ts-ignore
                  generalItem.text = item.text;
                  isHas = true;
              }
            }

            if(!isHas && !item.isNull) {
                // @ts-ignore
                this.generalFilter.push(item);
            }

            console.log(this.generalFilter);
        },

        removeGeneralFilter(index:number) {
            this.generalFilter.splice(index, 1);
        }
    }
})
