<script setup lang="ts">

</script>

<template>
  <div class="value-component flex gap-2 items-center">
    <router-link
        class="value-component__link"
        to="/"
    >Шаблон</router-link>
    <img src="/images/circle-arrow.svg"
         alt=""
         class="value-component__img"
    />
  </div>
</template>
