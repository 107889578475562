<script setup lang="ts">
import {ref} from 'vue';

import {INavTab} from "@/interfaces/Navigation";

const {tabs, onChangeTab} = defineProps({
  tabs: {
    type: Array as PropType<INavTab>,
    required: true
  },
  onChangeTab: {
    type: Function,
    required: true
  }
});

const activeTab = ref<string>(tabs[0].key || '');

const setActiveTab = (tab:INavTab) => {
  activeTab.value = tab.key;
  onChangeTab(tab);
}
</script>

<template>
  <nav class="navigation-tabs flex gap-1">
    <a v-for="tab in tabs"
       :key="tab.key"
       class="navigation-tabs__item font-bold"
       :class="{'active': activeTab === tab.key}"
       @click="setActiveTab(tab)"
    >{{ tab.label }}</a>
  </nav>
</template>

<style scoped lang="scss">
@import '@/assets/styles/config';

.navigation-tabs {
  border-bottom: 1px solid #EDEDF5;
  flex-wrap: wrap;

  &__item {
    padding: 12px 24px;
    border-bottom: 3px solid transparent;
    color: #353538;
    letter-spacing: 0.48px;
    line-height: 24px;
    cursor: pointer;
    font-family: Gilroy-Bold,serif;
    font-weight: 400 !important;

    &:hover,
    &:active,
    &.active {
      color: $colorBlue;
      border-color: $colorBlue;
    }
  }
}
</style>
