<script setup lang="ts">
const values = [
  {
    prop: 'ФИО',
    value: 'Иванов Иван Иванович'
  },
  {
    prop: 'Пол',
    value: 'мужской'
  },
  {
    prop: 'Дата рождения',
    value: '1992'
  }
]
</script>

<template>
  <div class="client-block-me">
    <ul class="client-block-me__list flex client-info-list">
      <li
          v-for="value in values"
          :key="value.prop"
          class="flex gap-2 justify-between"
      >
        <div class="client-info-prop">{{ value.prop }}</div>
        <div>
          {{ value.value }}
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

</style>
