<script setup lang="ts">
import {ref} from 'vue';

import NavigationTabs from "@/components/navigation/NavigationTabs.vue";
import CompaniesList from "@/components/companies/CompaniesList.vue";
import CompaniesTemplates from "@/components/companies-templates/CompaniesTemplates.vue";
import CompaniesReports from "@/components/companies-reports/CompaniesReports.vue";
import CompaniesArchive from "@/components/companies-archive/CompaniesArchive.vue";

import {INavTab} from "@/interfaces/Navigation";

const view = ref<string>('list');
const tabs: INavTab[] = [
  {
    key: 'list',
    label: 'Список кампаний'
  },
  {
    key: 'templates',
    label: 'Шаблоны'
  },
  {
    key: 'reports',
    label: 'Отчёты'
  },
  {
    key: 'archive',
    label: 'Архив'
  }
];

const handleChangeTab = (tab:INavTab) => view.value = tab.key;
</script>

<template>
  <div class="companies">
    <div class="companies__tabs">
      <NavigationTabs
          :onChangeTab="handleChangeTab"
          :tabs="tabs"
      />
    </div>

    <div class="companies__content">
      <CompaniesList v-if="view === 'list'" />
      <CompaniesTemplates v-if="view === 'templates'" />
      <CompaniesReports v-if="view === 'reports'" />
      <CompaniesArchive v-if="view === 'archive'" />
    </div>
  </div>
</template>
