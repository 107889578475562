<script setup lang="ts">
const {data} = defineProps({
  data: {
    type: Object,
    required: false,
    default: null
  }
})
</script>

<template>
  <router-link
      to="/clients/1123"
      class="cell-table-fio"
      v-if="data && data.fio"
  >
    {{ data.fio }}
  </router-link>
</template>

<style scoped lang="scss">
.cell-table-fio {
  color: #4046FF;
}
</style>
