<script setup lang="ts">
import {IBlockColor} from "@/interfaces/Blocks";

import BlockInfoColor from "@/components/block-info/BlockInfoColor.vue";

const blocksColor:IBlockColor[] = [
  {
    prop: 'Формат',
    value: 'Онлайн'
  },
  {
    prop: 'Продолжительность',
    value: '1ч 40м'
  },
  {
    prop: 'Дата регистрации',
    value: 'Данных нет'
  }
];
</script>

<template>
  <div class="block-info-visited">
    <h3 class="block-info-visited__title">
      Посещен ресурс
    </h3>

    <div class="block-info-visited__info-top mb-4">
      <h6 class="block-info-visited__info-top__subtitle">
        Цикл повышения квалификации
      </h6>
      <h6 class="block-info-visited__info-top__title">
        Вакцинация при ревматических заболеваниях: современное состояние проблемы
      </h6>
    </div>

    <div class="block-info-visited__info-blocks flex items-center flex-wrap gap-2">
      <BlockInfoColor
        v-for="block in blocksColor"
        :key="block.prop"
        :prop="block.prop"
        :value="block.value"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.block-info-visited {
  &__title {
    margin-bottom: 20px;
    color: #1D1D1F;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
  }

  &__info-top {
    &__subtitle {
      margin-bottom: 8px;
      color: #909099;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.16px;
    }

    &__title {
      color: #1D1D1F;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.36px;
    }
  }
}
</style>
